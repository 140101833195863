import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox/lightbox.service";
import { c1, c3, c4, c7, c8, c9, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-asidetoggle-component",
  templateUrl: "./gen-asidetoggle-component.component.html",
  styleUrls: ["./gen-asidetoggle-component.component.scss"],
})
export class GenASideToggleComponentComponent implements OnInit, OnDestroy {
  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService,

  ) { }

  @Input() page_id: string;
  @Input() tabData: Tab;
  page: any;
  c8_value: string;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  enableSideMenu: boolean;
  appInfo: any = { id: "app_info" };

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        ) {
          this.enableSideMenu = localStorage.getItem("mode")[7]==c8.SIDE? true: false;
        }
      }
    );

  }

  toggleSideMenu(event) {
    this.enableSideMenu = event;
    if (this.enableSideMenu) {
      this.c8_value = c8.SIDE;
    }else {
     this.c8_value = c8.NOSIDE
    }

    let modes = [{ id: c8.ID, value: this.c8_value },];
    this.mdw.changeMobileTheme(modes)
    this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)

    let hide_side_menu = this.enableSideMenu? 0: 1
    this.updateAppInfo(hide_side_menu)
  }

  updateAppInfo(value){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        appInfo.result["hide_side_menu"] = value;
       this.indexDBService.updateItem("item", appInfo.result);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
