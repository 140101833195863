import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox/lightbox.service";
import { c1, c10, c3, c4, c5, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-inavtabstyle-component",
  templateUrl: "./gen-inavtabstyle-component.component.html",
  styleUrls: ["./gen-inavtabstyle-component.component.scss"],
})
export class GenINavTabStyleComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService,
    private lightbox: Lightbox,

  ) { }

  @Input() page_id: string;
  @Input() tabData: Tab;

  page: any;
  c4_value: string;
  c10_value: string;
  c3_value: string;
  offCanvasSubscription: any;
  albums = [];
  title: string;
  offcanvasColors = offcanvasColors;
  enableNav: boolean;
  singleTab : string = c3.SINGLE;

  ngOnInit() {
    this.albums = [
      { id: c10.WIDE, src: '../../../assets/images/small/img-3-anavtab.jpg', caption: " Icon and Title" },
      { id: c10.STANDARD, src: '../../../assets/images/small/img-4-anavtab.jpg', caption: "Icon or Title" }
    ]

      this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        ) {


          this.c3_value = localStorage.getItem("mode")[2]
          this.c10_value = localStorage.getItem("mode")[9]

        }
      }
    );

  }
  open(index: number): void {
    // open lightbox
    this.c10_value = this.albums[index].id;
    let modes = [{ id: c10.ID, value: this.c10_value },];
    this.mdw.changeMobileTheme(modes)
    this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
