<div *ngIf="1==1">
  <div class="p-0 w-100 d-flex align-items-center justify-content-center rounded-4 overflow-hidden">
    <div class="card p-0 m-0 position-relative shadow-none w-100" style="max-width: 197px">
      <img [src]="theme?.emptyImageNavDrawerBar"  class="card-img" alt="empty">
      <div class="card-img-overlay p-0" [ngStyle]="setButtonBg" [ngClass]="fontSmaller?'':'rounded-3'">
        <div class="position-absolute start-0 end-0">

          <div *ngIf="albums && albums[0]?.en" class="card m-0 -p0 shadow-none rounded-0"
            style="background-color: rgba(0,0,0,{{albums[0].opacity}}); max-width: 197px; backdrop-filter: blur({{albums[0].blur}})">
            <img [src]="emptyImageP0" alt="empty" class="card-img">
            <div class="card-img-overlay border overflow-hidden m-0 p-0" [ngStyle]="setTopPartBg" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="part_0"></ng-template>
            </div>
          </div>

          <div *ngIf="albums && albums[1]?.en" class="card m-0 p-0 shadow-none" ngClass="{{albums[1].round}}"
          style="background-color: rgba(0,0,0,{{albums[1].opacity}}); max-width: 197px ; backdrop-filter: blur({{albums[1].blur}})"
          [ngStyle]="setTitleBg">
          <img [src]="emptyImageP1" alt="empty" class="card-img">
          <div class=" card-img-overlay border overflow-hidden m-0 p-0" [ngClass]="debug? 'border-1':'border-0'">
            <ng-template [ngTemplateOutlet]="part_1"></ng-template>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #part_0>
  <!-- ********the image in center*********** -->
  <div *ngIf="styleP0==1" class="row m-0 p-0">
    <div class=" col-12 d-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
      <img [src]='image' class="object-fit-cover mx-auto d-block" [ngStyle]="{'width':widthP0}" *ngIf="image">
      </div>
    </div>

  <!-- ********the image in background*********** -->
  <div *ngIf="styleP0==2" class="row m-0 p-0">
    <div class=" col-12 p-0 m-0">
      <img [src]='image' class="card-img m-0 p-0" class="object-fit-cover mx-auto d-block" [ngStyle]="{'width':widthP0}" *ngIf="image">
      <!-- <div class="card-img-overlay p-0" [ngStyle]="setImageP1" *ngIf="image"></div> -->
    </div>
  </div>
    <!-- ********the image & text togther*********** -->
    <div *ngIf="styleP0==3"
      class="row m-0 p-0 d-flex align-items-center justify-content-center">
      <div class=" col-5  m-0" [ngStyle]="{'padding':fontSmaller?'0px':albums[0].padding}">
        <img [src]='image' class="object-fit-cover mx-auto d-block" [ngStyle]="{'width':widthP0}" *ngIf="image">
      </div>
      <div class=" col-7 m-0 text-wrap mb-0" [ngStyle]="{'padding':fontSmaller?'0px':albums[0].padding}">
          <p type="text"
          class="card-title p-0 m-0 w-100 shadow-none border-0"
          [ngStyle]="lableColor">
          {{lable}}
        </p>
        <p class="p-0 m-0 text-wrap" [ngStyle]="sublableColor">
          {{sublable}}
        </p>
      </div>
    </div>
</ng-template>

<ng-template #part_1>
   <div class="row text-wrap -0 p-0">
    <div class=" col-12" >
        <div class="pt-3"  *ngFor="let tab of sortedTabList; let index = index; trackBy: tabTrackBy"    >
          <div class="row ps-3 d-flex align-items-center justify-content-center">
            <div class="col-2 d-flex align-items-center" [ngStyle]="iconStyle(tab.id)">
              <i
                class="font-size-18"
                [class.font-smaller]="false"
                *ngIf="tab?.icon"
                [class]="
                  builderService?.getIcon(tab?.icon).type == 'mir'
                    ? 'material-icons-outlined'
                    : 'material-icons'
                "
              >
                {{ builderService?.getIcon(tab?.icon).id }}
              </i>
            </div>
            <div class="col-10 d-flex align-items-center" *ngIf="tab?.title">
              <div
                class="text-truncate"
                [class.font-smaller]="true"
                style="max-width: 90%"
                [ngStyle]="titleStyle(tab.id)"
              >
                {{ tab?.title }}
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
</ng-template>





















