<div class="card p-0">
  <div class="card-body p-0">
    <div class="row m-0 overflow-auto">
      <div *ngFor="let menu of sortedMenuList; index as item ; trackBy:menuTrackBy" class="col-xl-6 col-sm-10 p-1" style="height: fit-content"
        [id]="menu.menu_id" >
        <div class="card mb-0 p-0">
          <div class="card-header p-1 d-flex align-items-center justify-content-between">
            <div class="font-smaller text-truncate" style="max-width: 60%"> {{ menu?.menu_id }} </div>
            <div>
              <i class="fas fa-check-circle text-primary font-smaller me-2"
                *ngIf="menu?.menu_id == defaultMenu?.menu_id"></i>
              <i *ngIf="menu?.menu_id != defaultMenu?.menu_id || current_menu.cat!='splash'"
                class="mdi mdi-delete danger-icon font-size-14 curser-pointer"
                (click)="deleteMenuItem(menu?.menu_id)"></i>
            </div>
          </div>
          <div class="card-body border border-1 rounded-3" [ngClass]="
              menu?.menu_id == current_menu?.menu_id
                ? 'border-primary'
                : 'border-secondary-subtle'
            " (click)="selectMenu(menu?.menu_id)">
            <app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller"
              [inputMenuId]="menu?.menu_id" [inputTab]='tab' [colors]="colors"></app-mobile-view-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
// old before changes 30/3/2024 Hazem
<div class="card-body border border-1 rounded-3" [ngClass]="
menu?.menu_id == current_menu?.menu_id
  ? 'border-primary'
  : 'border-secondary-subtle'
" (click)="selectMenu(menu?.menu_id)">
<app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller"
[inputMenuId]="menu?.menu_id" [inputTab]=null></app-mobile-view-slider>
</div> -->
