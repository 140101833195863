<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="card-header bg-transparent p-0 mb-3">
      <h6 class="offcanvas-header-text text-start">{{   'GENERALS.SOCIALBAR' | translate}}</h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let item of compoent_list">
          <div
            class="card offcanvas-primary mb-2"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selectedSettings"
            *ngIf="
              !selectedSettings ||
              (selectedSettings && selectedSettings.id !== item.id)
            "
            style="cursor: move"
          >
            <div class="row m-0 no-gutters align-items-center">
              <div class="col-md-4">
                <a  class="btn h-100 w-100 d-flex justify-content-center align-items-center" [style]="itemColor(item?.color)" href="#!" role="button"
                ><i [class]="item?.icon"></i
              ></a>
              </div>
              <div class="col-md-8 p-0">
                <div class="card-body card-btn-toggle position-relative ps-0">
                  <p class="card-text text-start offcanvas-header-text m-0">{{ item.title ? item.title : 'New Item' }}</p>
                  <button
                    class="btn btn-primary offcanvas-header-text p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 40px;
                      transform: translateY(-50%);
                    "
                    (click)="openSettings(item)"
                  >
                    <i class="fas fa-cog font-size-14"></i>
                  </button>
                  <button
                    class="btn btn-danger offcanvas-header-text p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 5px;
                      transform: translateY(-50%);
                    "
                    (click)="deleteitem(item)"
                  >
                    <i class="mdi mdi-delete font-size-14"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card bg-transparent bg-opacity-50 mb-2"
            cdkDrag
            *ngIf="selectedSettings && selectedSettings.id === item.id"
            style="border: 1px solid;"
          >
            <div class="card-body">
              <div class="mb-3 text-start">
                <div class="col-12 d-flex justify-content-end">
                  <div class="col-12 btn-group m-0" dropdown >
                    <button
                      type="button"
                      class="btn col-11 offcanvas-primary fs-6 d-flex align-items-center justify-content-start"

                    >



                    <a  class="btn  h-100 w-25 d-flex justify-content-center align-items-center" [style]="itemColor(selectedItem?.color)" href="#!" role="button"
                    ><i [class]="selectedItem?.icon"></i
                  ></a>
                  <span class="ms-1">{{selectedItem?.title}}</span>
                    </button>
                    <button
                      type="button"
                      class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
                      dropdownToggle
                    >
                      <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="col-12 dropdown-menu" *dropdownMenu>
                      <a *ngFor="let socialItem of getFilteredList()" class="col-12 dropdown-item d-flex " href="javascript: void(0);" (click)="selectItem(socialItem,item )"><i [class]="socialItem?.icon" [style]="'font-size:18px; color:'+socialItem?.color"></i> <span class="ms-1">{{socialItem?.title}}</span></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label
                for="text-color"
                class="offcanvas-header-text w-100"
                style="text-align: left"
              >
              {{   'GENERALS.URL' | translate}}
              </label>
              <div
                class="input-group offcanvas-border-primary border rounded-2"
                title="Using format option"
              >
                <input
                  type="text"
                  id="url"
                  #url
                  class="form-control input-lg offcanvas-primary border-0"
                  [(ngModel)]="item.url"
                  (change)="updateItemUrl(item, url.value)"
                />
              </div>
              </div>
              <div
                class="d-flex flex-wrap justify-content-between align-content-center gap-2"
              >
                <button
                  type="submit"
                  class="btn btn-secondary"
                  (click)="openSettings(null)"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveItem()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row m-0" *ngIf="!selectedSettings &&  getFilteredList().length >0">
      <button
        class="btn btn-secondary shadow-none col-6"
        type="button"
        (click)="addNewSwaper()"
      >
        Add New Item
      </button>
    </div>
  </div>
</div>
