import { BuilderService } from './../../sharedservices/builder.service';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { csLocale } from 'ngx-bootstrap/chronos';
import { MENU_BUTTON_STYLE, SPLASH_STYLE, splash_album_map, splash_config_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-splash",
  templateUrl: "./button-splash.component.html",
  styleUrls: ["./button-splash.component.scss"],
})
export class ButtonSplashComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    public indexDBService: IndexDBService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) { }
  // default values
  @Input() button: MenuButton;
  @Input() menuGroup: string;
  @Input() splashTab: Tab;
  @Input() fontResize = false;
  @Input() fontSmaller = true;

  appInfo: any;
  debug = false;
  emptyImage: string = './assets/images/mobile/emptyImageF.webp';   //100
  bg_image: string;
  start_color: string;
  end_color: string;
  mode: string;
  main_splash: any = {};
  tab: Tab;
  terms: any = { version: 0 };
  splash: any = {}
  version: string;
  bg_color: string;
  companyName: string;
  tab_style: string;
  theme: any;
  albums: any = []
  style: string;
  terms_title: string;
  sanitizedSource: any;


  ngOnInit() {
    this.theme = this.mdw.mobileTheme();
    this.emptyImage = this.theme.emptyImage;

    if (this.splashTab && this.splashTab.param && this.splashTab.param.splash) {
      this.tab = this.splashTab;
      this.tab_style = this.splashTab.param.splash.style


      this.detectSplashStyle(this.tab_style,'one');
    } else if (this.menuGroup) {
      this.getTab(this.menuGroup)
    } else {

      this.detectSplashStyle("01", 'two');
    }

    if (this.button) {
      this.style = this.button.button_style;
       }
    this.setSplashBg();
    this.getAppLogo();



  }

  getTab(tab_id) {
    const tab = this.indexDBService.getItem("tab", tab_id);
    tab.onsuccess = (event) => {
      if (tab.result) {
        this.tab = tab.result;
        if (this.tab && this.tab.param && this.tab.param.splash) {
          this.tab_style = this.tab.param.splash.style;
          this.detectSplashStyle(this.tab_style, 'three');
        }
      }
    }
  }
  getAppLogo() {
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        this.appInfo = appInfo.result;
        this.companyName = this.appInfo.company_official_name ? this.appInfo.company_official_name : this.appInfo.company_name ? this.appInfo.company_name : this.appInfo.channel_name
      }
    }
  }
  get setTabBg() {
    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    }
    else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color };
    } else {
      return { background: "unset" };
    }
  }

  get setBgImage() {
    if (this.splash.image) {
      return {
        "background-image": `url(${this.splash.image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    }
  }

  get setButtonBg() {
    return {
      "background": this.terms.button_bgcolor,
      "color": this.terms.button_text_color,
      'font-size': this.fontSmaller ? '3px' : '10px',
    };
  }

 get setTncbBg() {
  if (this.terms.tnc_bgcolor){
    return {
      "background": this.terms.tnc_bgcolor,
    };
  }

  }
 get setTitleBg() {
  if (this.terms.tnc_bgcolor && this.tab_style == SPLASH_STYLE.STYLE_05){
    return {
      "background": this.terms.tnc_bgcolor
    };
  }

  }


  setSplashBg() {
    if (this.splash.bgimage) {
      this.bg_image = this.splash.bgimage;
    } else if (this.splash.bgstart && this.splash.bgend) {
      this.bg_image = null;
      this.start_color = this.splash.bgstart ? this.splash.bgstart : null;
      this.end_color = this.splash.bgend ? this.splash.bgend : null;
    } else if (!this.splash.bgend && this.splash.bgstart) {
      this.bg_image = null;
      this.bg_color = this.splash.bgstart

    } else if (!this.splash.bgstart && this.splash.bgend) {
      this.bg_image = null;
      this.bg_color = this.splash.bgend;
    } else {
      this.bg_image = null;
      this.bg_color = null;
      this.start_color = null;
      this.end_color = null;
    }

  }
  detectSplashStyle(style, from) {
    if (style) {
       this.albums = splash_album_map[style].albums;

        switch (style) {
        //we will carry everything except image and bgimage
        case SPLASH_STYLE.STYLE_01:
          this.splash.ovalImage = null;
          this.updateButtonSplashItem('image',SPLASH_STYLE.STYLE_01);
          this.splash.title = null;
          this.splash.bgimage = null;
          this.splash.bgstart = null;
          this.splash.bgend = null;

          // this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_01);
          // this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_01);

          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_01)
            } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_01].tab_part;
          }
          break;

        case "02":
          this.splash.ovalImage = null;
          this.updateButtonSplashItem('image',SPLASH_STYLE.STYLE_02);
          this.updateButtonSplashItem('title',SPLASH_STYLE.STYLE_02);
          this.splash.bgimage = null;
          this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_02);
          this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_02);


          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_02)
            this.terms.tnc_bgcolor =null;
          } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_02].tab_part;
          }

          break;

        case "03":
          this.splash.ovalImage = null;
          this.splash.image = null;
          this.splash.title = null;
          this.updateButtonSplashItem('bgimage',SPLASH_STYLE.STYLE_03);
          this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_03);
          this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_03);
          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_03)
            this.terms.tnc_bgcolor =null;

          } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_03].tab_part;
          }
          break;


        case "04":
          this.updateButtonSplashItem('ovalImage',SPLASH_STYLE.STYLE_04);
          this.updateButtonSplashItem('image',SPLASH_STYLE.STYLE_04);
          this.updateButtonSplashItem('title',SPLASH_STYLE.STYLE_04);
          this.splash.bgimage = null;
          this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_04);
          this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_04);

          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_04)
          } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_04].tab_part;
          }

          break;

        case "05":
          this.updateButtonSplashItem('ovalImage',SPLASH_STYLE.STYLE_05);
          this.updateButtonSplashItem('image',SPLASH_STYLE.STYLE_05);
          this.updateButtonSplashItem('title',SPLASH_STYLE.STYLE_05);
          this.splash.bgimage = null;
          this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_05);
          this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_05);

          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_05)
          } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_05].tab_part;
          }
          break;

        case "06":
          this.updateButtonSplashItem('ovalImage',SPLASH_STYLE.STYLE_06);
          this.updateButtonSplashItem('image',SPLASH_STYLE.STYLE_06);
          this.updateButtonSplashItem('title',SPLASH_STYLE.STYLE_06);
          this.splash.bgimage = null;
          this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_06);
          this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_06);

          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_06)
            this.terms.tnc_bgcolor =null;
          } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_06].tab_part;
          }
          break;


        case "07":
          this.updateButtonSplashItem('ovalImage',SPLASH_STYLE.STYLE_07);
          // this.splash.ovalImage = null;
          this.updateButtonSplashItem('image',SPLASH_STYLE.STYLE_07);
          this.updateButtonSplashItem('title',SPLASH_STYLE.STYLE_07);
          this.splash.bgimage = null;
          this.updateButtonSplashItem('bgstart',SPLASH_STYLE.STYLE_07);
          this.updateButtonSplashItem('bgend',SPLASH_STYLE.STYLE_07);

          // update the tab part
          if (this.tab && this.tab.param && this.tab.param.splash) {
            this.updateAlltabSplashItem(SPLASH_STYLE.STYLE_07)
            this.terms.tnc_bgcolor =null;
          } else {
            this.terms = splash_config_map[SPLASH_STYLE.STYLE_07].tab_part;
          }
          break;


      }
      this.splash.desc = this.button.button_sublabel;
      this.style = this.button.button_style;
      this.setSplashBg();
    }

  }

  updateAlltabSplashItem(style) {
    this.updateTabSplashItem('title', style);
    this.updateTabSplashItem('button_title', style);
    this.updateTabSplashItem('button_bgcolor', style);
    this.updateTabSplashItem('button_text_color', style);
    this.updateTabSplashItem('tnc_text_color', style);
    this.updateTabSplashItem('tnc_link_color', style);
    this.updateTabSplashItem('tnc_bgcolor', style);

  }

  updateTabSplashItem(item, style) {
    if (item == 'title') {
      let title = this.fontSmaller ? this.builderService.RemoveHeadingHTML(this.tab.param.splash[item]) : this.tab.param.splash[item];
      let template_title = this.fontSmaller ? this.builderService.RemoveHeadingHTML(splash_config_map[style].tab_part[item]) : splash_config_map[style].tab_part[item];
      let term_title= this.tab.param.splash[item] !== null? title : template_title;
      this.terms[item] = this.sanitizer.bypassSecurityTrustHtml(term_title)
      } else {
      this.terms[item] = this.tab.param.splash[item] ? this.tab.param.splash[item] : splash_config_map[style].tab_part[item];
    }
  }

  updateButtonSplashItem(item, style){
    if (item === 'ovalImage') {   // only read, no set
      this.splash.ovalImage = splash_config_map[style].button_part.ovalImage;
    }
    if (item === 'image') {
      if (style == SPLASH_STYLE.STYLE_01){
        this.splash.image = this.button.button_images[style].url !== null ? this.button.button_images[style].url : splash_config_map[style].button_part.images[style].url;

     }else {
        this.splash.image = this.button.button_images['default'].url !== null ? this.button.button_images['default'].url : splash_config_map[style].button_part.images[style].url;
      }

    }
      if (item==='bgstart'){
      this.splash.bgstart = this.button.button_bgstart !== null? this.button.button_bgstart : splash_config_map[style].button_part.start_color;
    }
    if (item==='bgend'){
      this.splash.bgend = this.button.button_bgend !== null? this.button.button_bgend : splash_config_map[style].button_part.end_color;
    }
    if (item==='bgimage'){
      this.splash.bgimage = this.button.button_bgimage !== null ? this.button.button_bgimage : splash_config_map[style].button_part.bg_image;
    }
    if ( item==='title'){
        let button_label = this.fontSmaller ? this.builderService.RemoveHeadingHTML(this.button.button_label) : this.button.button_label;
        let template_title = this.fontSmaller ? this.builderService.RemoveHeadingHTML(splash_config_map[style].button_part.title) : splash_config_map[style].button_part.title;
        this.splash.title = this.button.button_label !== null ? button_label : template_title;
        this.splash.title = this.sanitizer.bypassSecurityTrustHtml(this.splash.title)
      }

  }



  ngOnDestroy(): void {
  }



}
