<div
  class="container-fluid p-0 d-flex flex-column overflow-hidden"
  style="height: calc(100% - 60px)"
>
  <div class="clearfix m-0 h-100">
    <div
      class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 order-2 order-sm-1 d-flex p-0"
      [class]="
        showCanvas ? 'justify-content-end' : 'justify-content-start ps-4'
      "
    >
      <button
        type="button"
        class="btn btn-sm font-size-24 header-item p-0"
        (click)="showOffcanvas()"
      >
        <i [class]="showCanvas ? 'mdi mdi-window-close' : 'mdi mdi-menu'"></i>
      </button>
    </div>
    <div
      id="side-nave"
      class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 float-start h-100 animate-go-in px-3 me-2 border-end"
    >
      <div class="row mt-2">
        <span class="font-size-16">Store Library</span>
      </div>
      <div class="row mt-3">
        <div class="col">
          <a
            href="javascript:void(0);"
            class="px-3 py-2 rounded bg-opacity-50 d-block mb-2"
            [class.bg-light]="!selectedItem"
            (click)="getMediaBykeyword(null)"
          >
            All
            <span class="badge text-bg-info float-end bg-opacity-100">10</span>
          </a>
          <a
            href="javascript:void(0);"
            class="px-3 py-2 rounded bg-opacity-50 d-block mb-2"
            [class.bg-light]="selectedItem === 'calendar'"
            (click)="getMediaBykeyword('calendar')"
          >
            Calendar
            <span class="badge text-bg-info float-end bg-opacity-100">52</span>
          </a>
        </div>
      </div>
    </div>
    <div id="media-body" class="p-0 h-100">
      <div class="row m-0">
        <div class="col">
          <ul class="d-flex justify-content-end ps-0 m-0">
            <button
              class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-1 p-2 d-none"
              id="pagination_back"
              (click)="backPage()"
            >
              <i class="mdi mdi-chevron-left font-size-18"></i>
              <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
            </button>
            <button
              class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-2 pe-1"
              id="pagination_next"
              (click)="nextPage()"
            >
              <p class="mb-0">{{ "GENERALS.NEXT" | translate }}</p>
              <i class="mdi mdi-chevron-right font-size-18"></i>
            </button>
          </ul>
        </div>
      </div>
      <div class="row m-0" style="height: calc(100% - 64px)">
        <ngx-simplebar id="scroll" class="h-100" #scrollRef>
          <div class="row m-0 flex-grow-1">
            <div class="col h-100">
              <div class="row mb-3">
                <dropzone
                  #main
                  class="dropzone"
                  (addedFiles)="filesAdd($event)"
                  [config]="dropzoneConfig"
                ></dropzone>
              </div>
              <div
                class="grid-custom"
                style="--grid-col-size: 8.125rem; --grid-gap: 1rem"
              >
                <ng-container *ngFor="let image of mediaData; let i = index">
                  <label
                    [for]="data?.id + image?.id"
                    class="p-2 rounded curser-pointer m-0 media-hover d-flex flex-column"
                    [class.media-selected]="isMediaSelected(image)"
                    [id]="i"
                  >
                    <div class="row flex-grow-1">
                      <div
                        class="mx-auto col-8 d-flex justify-content-center p-0 h-100"
                        style="max-height: 8.125rem"
                      >
                        <div
                          class="position-relative d-flex w-100 p-1 rounded border bg-white"
                        >
                          <input
                            #checkbox
                            type="checkbox"
                            [id]="data?.id + image?.id"
                            (change)="addImages(image, checkbox?.checked)"
                            [checked]="isMediaSelected(image)"
                            class="form-check-input position-absolute mt-2 ms-2"
                          />
                          <img
                            class="object-fit-contain border rounded w-100"
                            [src]="image?.permanentUrl || image?.url"
                            [alt]="image?.filename"
                            style="
                              max-height: 100%;
                              max-width: 100%;
                              background-color: rgba(241, 241, 241, 1);
                            "
                          />
                          <button
                            class="btn btn-primary btn-sm position-absolute"
                            style="bottom: 0.5rem; right: 0.5rem"
                            (click)="openSlaider($event, image, i)"
                          >
                            <i class="far fa-eye"></i>
                          </button>
                          <div
                            *ngIf="image?.status === 'upload'"
                            class="position-absolute top-0 bottom-0 start-0 end-0 bg-dark bg-opacity-75 d-flex align-items-center justify-content-center rounded"
                          >
                            <div
                              role="status"
                              class="spinner-border text-white"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2 text-center text-truncate">
                      {{ image?.filename }}
                      <div>png</div>
                    </div>
                  </label>
                </ng-container>
              </div>
            </div>
          </div>
        </ngx-simplebar>
        <div
          id="carouselExampleCaptionDiv"
          class="w-50 animate-right-out d-flex flex-column"
          style="background-color: rgba(241, 241, 241, 1)"
        >
          <div
            class="d-flex align-items-center justify-content-between p-3 fw-semibold"
          >
            Preview
            <button
              class="btn btn-close"
              (click)="closeSlaider($event)"
            ></button>
          </div>
          <carousel
            id="carouselExampleCaption"
            [interval]="0"
            [(activeSlide)]="imageIndex"
            [showIndicators]="false"
            class="carousel slide w-100 h-100"
          >
            <div class="carousel-inner w-100 h-100" role="listbox">
              <slide
                class="carousel-item h-100"
                *ngFor="let image of mediaPreviewData"
              >
                <img
                  [src]="image?.permanentUrl || image?.url"
                  [alt]="image?.filename"
                  class="object-fit-contain border rounded"
                  style="max-width: 100%; max-height: 100%"
                />
              </slide>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="border-top d-flex align-items-center flex-row-reverse p-2"
  style="height: 60px"
>
  <button
    class="btn btn-success ms-2"
    [disabled]="mediaSelectedImages.length === 0"
    (click)="useSelected()"
  >
    Done
  </button>
  <button class="btn btn-danger" (click)="cancelMedia()">Cancel</button>
</div>
