
<div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 overflow-hidden w-100 z-0" style="max-width: 260px">
 <tabset  class="nav-pills nav-justified"  #tabset id="tabset">
    <tab heading="Android" id="android" (selectTab)="onChangeView($event)">
      <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
    </tab>
    <tab heading="iOS" id="ios" (selectTab)="onChangeView($event)">
      <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
    </tab>
  </tabset>

<!-- Tab Content -->
<ng-template #TabContent>
  <div class="mt-2">
    <div *ngIf="theme?.view===mobileView?.a_full || theme?.view===mobileView?.i_full ">
      <div class="p-0 col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
        style="max-width: 260px">
        <div class="position-relative">
        <div class="row m-0 p-0 ">
          <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
            <div class="card p-0 m-0" style="background-color: transparent !important;">
              <img [src]="theme?.emptyImage" class="card-img " alt="empty" >
              <div class="card-img-overlay p-0 ">
                <ng-template  [ngTemplateOutlet]="Components"></ng-template>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="theme?.sidemode"  style="max-width: 197px" class="position-absolute start-0 top-0">
          <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
        </div>
        </div>
      </div>
    </div>

<!-- Android Single Tab , iOS Single Tab or Component ---- All do not have bottom but ****only App bar on the top****-->
<div *ngIf="theme?.view === mobileView?.a_main_tab ||theme?.view === mobileView?.i_main_tab ||theme?.view === mobileView?.a_component ||theme?.view === mobileView?.i_component" >
  <div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
    style="max-width: 260px">
<div class="position-relative">
  <div class="row m-0 p-0">
    <div class="col-12 p-0">
      <app-brand-bar [fontResize]="fontResize" class="w-100"></app-brand-bar>
    </div>
    <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
      <div class="card p-2 m-0 rounded-0" style="background-color: transparent !important;">
        <img [src]="theme?.emptyImage" class="card-img" alt="empty">
        <div class="card-img-overlay p-0">
          <ng-template [ngTemplateOutlet]="Components"></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="theme?.sidemode"  style="max-width: 197px" class="position-absolute start-0 top-0">
    <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
  </div>
</div>
  </div>
</div>

<div *ngIf="theme?.view === mobileView?.a_main_many_bottom_wide || theme?.view === mobileView?.a_main_many_bottom_std || theme?.view === mobileView?.i_main_bottom_small
|| theme?.view === mobileView?.i_main_bottom_large || theme?.view === mobileView?.i_main_top_small || theme?.view === mobileView?.i_main_top_large">
  <div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
    style="max-width: 260px">
    <div class="position-relative">
      <div class="row m-0 p-0">
        <div class="col-12 p-0">
          <app-brand-bar [fontResize]="fontResize" class="w-100"></app-brand-bar>
        </div>
             <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
          <div class="card p-2 m-0 rounded-0 shadow-none" style="background-color: transparent !important;">
            <img [src]="theme?.emptyImage" class="card-img" alt="empty">
            <div class="card-img-overlay p-0" >
              <ng-template [ngTemplateOutlet]="Components"></ng-template>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <app-navbar [fontResize]="fontResize" [tab_id]="inputTab?.id" class="w-100"></app-navbar>
        </div>
      </div>
      <div *ngIf="theme?.sidemode"  style="max-width: 197px" class="position-absolute start-0 top-0">
        <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
      </div>
    </div>

  </div>
</div>

<div  *ngIf="theme?.view === mobileView?.a_main_many_top_wide || theme?.view === mobileView?.a_main_many_top_std">
  <div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
    style="max-width: 260px">
    <div class="position-relative">
      <div class="row m-0 p-0">
        <div class="col-12 p-0 m-0">
          <app-brand-bar [fontResize]="fontResize" class="w-100"></app-brand-bar>
        </div>
        <div class="col-12 p-0 pb-0 m-0">
          <!-- <div class="col-12 p-0 pb-2">    -->
          <app-navbar [fontResize]="fontResize" [tab_id]="inputTab?.id" class="w-100"></app-navbar>
        </div>
        <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
          <div class="card p-0 m-0 rounded-0"  style="background-color: transparent !important;">
            <img [src]="theme?.emptyImage" class="card-img" alt="empty">
            <div class="card-img-overlay p-0">
              <ng-template [ngTemplateOutlet]="Components"></ng-template>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="theme?.sidemode"  style="max-width: 197px" class="position-absolute start-0 top-0">
        <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
      </div>
    </div>
  </div>
</div>


</div>
</ng-template>
<!-- Components Content -->
<ng-template #Components>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENCHAT" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNEL" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENBOT" [fontResize]="fontResize"  [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALL_LOG" [fontResize]="fontResize"  [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VIDEOAUDIO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CONTACT" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNELLIST" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINEGROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKING" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.GROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKINGLIST" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOK" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALENDAR" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VALIDATE_TICKET" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.REDEEM_TICKET" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.INVITATION" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENGROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINECHANNEL" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.EMPTY" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-feeds *ngIf="inputTab && inputTab?.module === tabNames?.FEED" [fontResize]="fontResize" [inputTab]="inputTab"></app-feeds>
  <app-palette *ngIf="inputTab && inputTab?.module === tabNames?.SPLASH" [fontResize]="fontResize" [inputTab]="inputTab"></app-palette>
  <app-web-view *ngIf="inputTab && inputTab?.module === tabNames?.WEBVIEW" [fontResize]="fontResize" [inputTab]="inputTab"></app-web-view>
  <app-video-view *ngIf="inputTab && inputTab?.module === tabNames?.VIDEO" [fontResize]="fontResize" [inputTab]="inputTab"></app-video-view>
  <app-search-view *ngIf="inputTab && inputTab?.module === tabNames?.SEARCH" [fontResize]="fontResize" [inputTab]="inputTab"></app-search-view>

 <app-menus *ngIf="(inputData && (inputData?.module === tabNames?.MENU ||
                                 inputData?.module === tabNames?.PAGE ||
                                 inputData?.module === tabNames?.STORE ||
                                 inputData?.module === tabNames?.POLL) ||
                                 (splashTab && inputData?.module === tabNames?.SPLASH) ||

                    inputTab  && (inputTab?.module === tabNames?.MENU ||
                                 inputTab?.module === tabNames?.PAGE ||
                                 inputTab?.module === tabNames?.STORE ||
                                 inputTab?.module === tabNames?.POLL) ||

                                 (splashTab && inputTab?.module === tabNames?.SPLASH) ) "
                                  [fontResize]="fontResize"
                                  [inputTab]="inputTab"
                                  [inputMenuId]="inputMenuId"
                                  class="h-100 d-block"
                                  [splashTab]="splashTab"
                                  [fixedTop]=true
                                  [colors]="colors">
</app-menus>
 <app-qr-view *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-view>
  <app-qr-page-view *ngIf="inputTab && inputTab?.module === tabNames?.QR_PAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-page-view>
  <app-map-search-view *ngIf="inputTab && inputTab?.module === tabNames?.MAP_SEARCH" [fontResize]="fontResize" [inputTab]="inputTab"></app-map-search-view>
  <app-chat-view-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontResize]="fontResize" [inputTab]="inputTab"></app-chat-view-component>
</ng-template>
