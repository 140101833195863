<div class="row">
  <form class="app-search d-block col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10 order-1 order-sm-2 px-3">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-10">
        <div class="position-relative">
          <input id="searchContact" type="text" class="form-control" placeholder="Search..." (keyup)="ContactSearch()">
          <span class="bx bx-search-alt"></span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-2 d-flex justify-content-end mt-0-lg mt-1">
        <button class="btn btn-danger btn-rounded">Remove</button>
      </div>
    </div>
  </form>
</div>

<div class="container-fluid p-0 d-flex flex-column overflow-hidden" style="height: calc(100% - 60px)">
  <div class="clearfix m-0 h-100">
    <div class="row m-0">
      <tabset class="nav-tabs nav-tabs-custom col">
          <tab class="mt-4">
            <ng-template tabHeading>All</ng-template>
            <ng-template [ngTemplateOutlet]="TaskAll"></ng-template>
          </tab>
          <!-- <tab class="mt-4">
            <ng-template tabHeading>Tabs</ng-template>
            <ng-template [ngTemplateOutlet]="TaskTabs"></ng-template>
          </tab>
          <tab class="mt-4">
            <ng-template tabHeading>Menus</ng-template>
            <ng-template [ngTemplateOutlet]="TaskMenus"></ng-template>
          </tab> -->
      </tabset>
    </div>
  </div>
</div>

<ng-template #TaskAll>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
    <div class="row" *ngIf="sortedHomeList.length != 0">
      <div class="col-2">
        <h2>Home</h2>
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let homeTab of sortedHomeList; index as item" style="height: fit-content" class="col-2" [id]="homeTab.id">
            <div class="card mb-0 p-0" *ngIf="homeTab.id != '0'">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-smaller text-truncate">
                  {{ homeTab?.id }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(homeTab, 'tab')">
                <!-- <app-mobile-view-slider *ngIf="homeTab" [enableStatic]="true" [fontSmaller]="fontSmaller" [inputTab]="homeTab" [staticTab]="homeTab"></app-mobile-view-slider> -->
                <app-mobile-view-tab *ngIf="homeTab" [inputTab]="homeTab" [fontSmaller]="fontSmaller"></app-mobile-view-tab>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="sortedSideList.length != 0">
      <div class="col-2">
        <h2>Side</h2>
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let sideTab of sortedSideList; index as item" style="height: fit-content" class="col-2" [id]="sideTab.id">
            <div class="card mb-0 p-0" *ngIf="sideTab.id != '0'">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-smaller text-truncate">
                  {{ sideTab?.id }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(sideTab, 'tab')">
                <app-mobile-view-tab *ngIf="sideTab" [inputTab]="sideTab" [fontSmaller]="fontSmaller"></app-mobile-view-tab>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="sortedCustomList.length != 0">
      <div class="col-2">
        <h2>Custom</h2>
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let tab of sortedCustomList; index as item" style="height: fit-content" class="col-2" [id]="tab.id">
            <div class="card mb-0 p-0" *ngIf="tab.id != '0'">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-smaller text-truncate">
                  {{ tab?.id }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(tab, 'tab')">
                <app-mobile-view-slider *ngIf="tab"   [fontSmaller]="fontSmaller" [inputTab]="tab" [enableStatic]="true" [staticTab]="tab"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let menus of sortedHomeMenuList; index as item ; trackBy:menuTrackBy">
      <div class="row" *ngIf="menus.menus.length != 0">
        <div class="col-2 text-wrap">
          <h2>{{menus.menu_id}}</h2>
        </div>
        <div class="col">
          <div class="row">
            <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
              <div class="card mb-0 p-0">
                <div class="card-header p-1 d-flex align-items-center justify-content-between">
                  <div class="font-smaller text-truncate">
                    {{ menu?.menu_id }}
                  </div>
                </div>
                <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                  <app-mobile-view-slider *ngIf="menu"  [enableStatic]="true" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab' [stat]='tab' [inputTab]='tab' [colors]="colors"></app-mobile-view-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let menus of sortedSideMenuList; index as item ; trackBy:menuTrackBy">
      <div class="row" *ngIf="menus.menus.length != 0">
        <div class="col-2 text-wrap">
          <h2>{{menus.menu_id}}</h2>
        </div>
        <div class="col">
          <div class="row">
            <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
              <div class="card mb-0 p-0">
                <div class="card-header p-1 d-flex align-items-center justify-content-between">
                  <div class="font-smaller text-truncate">
                    {{ menu?.menu_id }}
                  </div>
                </div>
                <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                  <app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab'  [enableStatic]="true"  [staticTab]="tab"   [staticMenuId]="menu?.menu_id" [colors]="colors"></app-mobile-view-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #TaskTabs>
  <div class="overflow-x-hidden overflow-y-auto" style="height: 70vh">
    <div class="row" *ngIf="sortedHomeList.length != 0">
      <div class="col-2">
        <h2>Home</h2>
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let tab of sortedHomeList; index as item" style="height: fit-content" class="col-2" [id]="tab.id">
            <div class="card mb-0 p-0" *ngIf="tab.id != '0'">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-smaller text-truncate">
                  {{ tab?.title }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(tab, 'tab')">
                <app-mobile-view-slider *ngIf="tab" [fontSmaller]="fontSmaller" [inputTab]="tab" [enableStatic]="true" [staticTab]="tab"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="sortedSideList.length != 0">
      <div class="col-2">
        <h2>Side</h2>
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let tab of sortedSideList; index as item" style="height: fit-content" class="col-2" [id]="tab.id">
            <div class="card mb-0 p-0" *ngIf="tab.id != '0'">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-smaller text-truncate">
                  {{ tab?.title }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(tab, 'tab')">
                <app-mobile-view-slider *ngIf="tab" [fontSmaller]="fontSmaller" [inputTab]="tab" [enableStatic]="true" [staticTab]="tab"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="sortedCustomList.length != 0">
      <div class="col-2">
        <h2>Custom</h2>
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let tab of sortedCustomList; index as item" style="height: fit-content" class="col-2" [id]="tab.id">
            <div class="card mb-0 p-0" *ngIf="tab.id != '0'">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-smaller text-truncate">
                  {{ tab?.title }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(tab, 'tab')">
                <app-mobile-view-slider *ngIf="tab" [fontSmaller]="fontSmaller" [inputTab]="tab" [enableStatic]="true" [staticTab]="tab"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #TaskMenus>
  <div class="overflow-x-hidden overflow-y-auto" style="height: 70vh">
    <div *ngFor="let menus of sortedHomeMenuList; index as item ; trackBy:menuTrackBy">
      <div class="row" *ngIf="menus.menus.length != 0">
        <div class="col-2 text-wrap">
          <h2>{{menus.name}}</h2>
        </div>
        <div class="col">
          <div class="row">
            <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
              <div class="card mb-0 p-0">
                <div class="card-header p-1 d-flex align-items-center justify-content-between">
                  <div class="font-smaller text-truncate">
                    {{ menu?.menu_id }}
                  </div>
                </div>
                <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                  <app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab' [enableStatic]="true"  [staticTab]="tab"   [staticMenuId]="menu?.menu_id" [colors]="colors"></app-mobile-view-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let menus of sortedSideMenuList; index as item ; trackBy:menuTrackBy">
      <div class="row" *ngIf="menus.menus.length != 0">
        <div class="col-2 text-wrap">
          <h2>{{menus.name}}</h2>
        </div>
        <div class="col">
          <div class="row">
            <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
              <div class="card mb-0 p-0">
                <div class="card-header p-1 d-flex align-items-center justify-content-between">
                  <div class="font-smaller text-truncate">
                    {{ menu?.menu_id }}
                  </div>
                </div>
                <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                  <app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab' [enableStatic]="true"  [staticTab]="tab"   [staticMenuId]="menu?.menu_id" [colors]="colors"></app-mobile-view-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->
