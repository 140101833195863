<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form class="needs-validation w-100" [formGroup]="labelform">
      <div class="mb-3">
        <label for="access" class="text-white w-100" style="text-align: left">
          Access
        </label>
        <select
          id="access"
          class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
          (change)="updateAccess($event)"
          formControlName="access"
        >
          <option value="0">Any</option>
          <option value="1">Admin</option>
        </select>
      </div>
      <div class="m-0">
        <label for="required" class="text-white w-100" style="text-align: left">
          Required Registration
        </label>
        <select
          id="required"
          class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
          (change)="updateRegistration($event)"
          formControlName="loginType"
        >
          <option value="null">No Login</option>
          <option value="email">Email</option>
          <option value="msisdn">Mobile</option>
        </select>
      </div>
    </form>
  </div>
</div>
