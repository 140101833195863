<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form class="needs-validation" [formGroup]="messageform">
        <div class="col-12 mb-3" *ngIf="page_id === login_types.EMAIL">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Onboarding Page Title
          </label>
          <div
            class="input-group offcanvas-border-primary border-0 m-0 p-0 rounded-0"
            title="Using format option"
          >
            <ckeditor
              id="lablecolor"
              formControlName="lableControl"
              class="form-control input-lg offcanvas-ck-primary border-0 w-100 p-0 rounded-0"
              [config]="defaultConfig"
              [editor]="Editor"
              data="button_label"
              (blur)="onSubmit()"
              (ready)="onReady($event)"
              [ngClass]="{
                'is-invalid': submitted && f.lableControl.errors
              }"
            ></ckeditor>
            <div
              *ngIf="submitted && f.lableControl.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.lableControl.errors.required">
                {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
              </div>
              <div *ngIf="f.lableControl.errors.message">
                {{ f.lableControl.errors.message }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" *ngIf="page_id === login_types.MSISDN">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Onboarding Page Title
          </label>
          <div
            class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
            title="Using format option"
          >
            <textarea
              class="form-control input-lg border-0 offcanvas-primary"
              id="description"
              formControlName="lableControl"
              [ngClass]="{
                'is-invalid': submitted && f.lableControl.errors
              }"
              rows="6"
              (blur)="onSubmit()"
              [placeholder]="'Enter Message'"
            ></textarea>
            <div
              *ngIf="submitted && f.lableControl.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.lableControl.errors.required">
                {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
              </div>
              <div *ngIf="f.lableControl.errors.pattern">
                Variables are missing $app_name or $code
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-danger btn-sm"
              (click)="resetToDefulatMessage()"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
