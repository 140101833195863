import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MENU_BUTTON_CODE, MENU_BUTTON_STYLE, c1 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { ButtonOption, MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-dropdown",
  templateUrl: "./button-dropdown.component.html",
  styleUrls: ["./button-dropdown.component.scss"],
})
export class ButtonDropdownComponent implements OnInit {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private formBuilder: UntypedFormBuilder,
    public indexDBService: IndexDBService,
    public builderService: BuilderService

  ) { }

  selectform: UntypedFormGroup; // bootstrap validation form

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize= false;
  @Input() fontSmaller= false;
  @Input() fixedTop : boolean = false;
  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() colors:any;

//////////////////// what have been used //////////////

currentMenuSubscription: any;
updateBuilderComponentSubscription: any;
offCanvasSubscription: any;
colorsComponentSubscription: any;

helper:string    // = 'Helper Text';
error: string    //'error';  error messages

helper_color: string //='#8e8e93'
error_color: string  //= '#FF0000' ;
leading_icon: string = null// 'fas fa-heart' ;
trailing_icon: string = null;   //= 'fas fa-check-circle';
error_icon: string = 'fas fa-exclamation-circle';
leading_icon_color: string
trailing_icon_color: string

upArrow_icon: string = 'bx bx-caret-up' ;
downArrow_icon: string = 'bx bx-caret-down' ;
downArrow_icon_ios: string =  'mdi mdi-chevron-down' //'mdi mdi-chevron-down-box';

option_selected_icon_ios = 'fas fa-check';

optionSelected_ios ='mdi mdi-check'


suffix: string;
suffix_color: string
prefix : string;
prefix_color : string;

border_color : string;
border_color_ios: string;

option_active_bg_color: string;

bg_image: string
bg_color: string ;
start_color: string;
end_color: string;
image: string;
album_button: any;
albums: any;
emptyImage: string;
no_buttons: number =1;
emptyImageP0: string;
emptyImageP1: string;
widthP0: string;
label: string;
sublabel: string;
label_color: string;
sublabel_color: string;
settings: any;
primary_color: string;
active_icon_color_ios: string;
pStyle: number;
cellHight: string;
labellPadding: string;
sublabelPadding: string;
padding: string;
album_input_map: any;
sublabelSetting: any;
labelSetting: any
isAndroid : boolean;
current_button: any;
isActive: boolean;
optionSetting: any;
optionCard: any;
optionCardPadding: any;
optionPadding: any;
options : any;
border_default_color: string // = '#B8B8B8';
activeOption: any;
outline: string;
menu_bg_color : string;
activeOption_icon: string;
leading_icon_flag : boolean= false;
noOptions: number;
inputText: string;
inputText_color: string;


/// colors to be common into the tab section///
light: boolean = true;
color_schema: any;
color_schema_ios: any;

ngOnInit() {

  let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
  this.initialize(style,this.no_buttons)

  this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
    if (data && !data.fromButton){
      if (data["sortedButtonList"][this.row_id]){
        this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        this.current_button =data["currentButton"];
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
        console.log("thisisActive Menu", this.isActive);
      }

      if (data['colors']) {
        this.colors = data["colors"];
        this.light = data["light"];
        this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
        this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark
      }
      let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
      this.initialize(style,this.no_buttons)      }

  })

  this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
    (data) => {
     if (data) {
        this.colors = data;
        this.light = data.light
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
    })

  this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
    (data) => {
      // only for getting default button to change style for active or none active button style

       if (data && data.button){

        this.current_button =data.button;
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false

        console.log("thisisActive offconv", this.isActive);
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
         }
    }
  );

  this.updateBuilderComponentSubscription =
  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {

        // only to get different views for button for Android and iOS. if both same view we do not need this subscription.

        this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDRIOD? true: false;
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
  });

}

initialize(style, no_button){
  this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDRIOD? true: false;

  if (this.colors && this.button) {
    this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
    this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark

    // Static Button Content
   //this.leading_icon = this.isAndroid? this.button.button_icon: this.button.button_icon_ios
   this.helper = this.error? this.button.button_error: this.button.button_helper;
   this.trailing_icon = this.isAndroid? this.error? this.error_icon: this.isActive? this.upArrow_icon: this.downArrow_icon : this.downArrow_icon_ios
   this.options = this.button.button_option;   // all option values
   this.noOptions = this.options.length;

   this.activeOption = this.button.button_value[0];  // only one selected here.
   this.activeOption_icon = this.activeOption? this.isAndroid? this.activeOption.icon: this.activeOption.icon_ios: null;

   for( let i=0; this.button.button_option.length> i; i++  ){
    this.leading_icon_flag= this.button.button_option[i].icon? true : false;
    if (this.leading_icon_flag){
      break;
    }
   }

   // set the input as selected default
   this.button.button_input =this.activeOption? this.activeOption.label: null

   this.image = null
   this.bg_image = null
   this.start_color = null;
   this.end_color = null;

    // Theme Settings
     //convert the dropmenu style to same button-input style to standardize the code.
    let drop_style = this.convertStyle(style);
    let index = this.getStyle(drop_style)
     this.album_button= album_input_map[index];
    this.settings =  this.isAndroid?this.album_button.settings:this.album_button.settings_ios
    this.labelSetting =  this.isAndroid?this.album_button.label :this.album_button.label_ios ;
    this.sublabelSetting = this.isAndroid?this.album_button.sublabel: this.album_button.sublabel_ios;
    this.optionSetting = this.isAndroid?this.album_button.options:this.album_button.options_ios
    this.optionCard = this.isAndroid?this.album_button.options_setting:this.album_button.options_setting_ios


    this.cellHight = this.fontSmaller? this.settings.cellHight_s:this.settings.cellHight
    this.padding = this.fontSmaller?this.settings.padding_s: this.no_buttons===3?this.settings.padding['3'] :this.settings.padding['default']
    this.optionPadding = this.fontSmaller?this.optionSetting.padding_s: this.no_buttons===3?this.optionSetting.padding['3'] :this.optionSetting.padding['default']
    this.optionCardPadding = this.fontSmaller?this.optionCard.padding_s: this.no_buttons===3?this.optionCard.padding['3'] :this.optionCard.padding['default']

    console.log("optionCardPadding", this.optionCardPadding);

    this.labellPadding = this.fontSmaller?this.labelSetting.padding_s: this.no_buttons===3?this.labelSetting.padding['3'] :this.labelSetting.padding['default']
    this.sublabelPadding = this.fontSmaller?this.sublabelSetting.padding_s: this.no_buttons===3?this.sublabelSetting.padding['3'] :this.sublabelSetting.padding['default']





    // Colors
    this.themeColors(drop_style)
    this.helper_color = this.isAndroid? this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'helper', 'onSurfaceVariant', this.light)
    :this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'helper', 'onSurfaceVariant', this.light)


    this.error_color = this.isAndroid ? //this.color_schema.error : this.color_schema_ios.error
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'error', 'error', this.light)
    :this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'error', 'error', this.light)


    this.border_color = this.error ? this.error_color : this.isActive ?  // this.color_schema.primary :  this.color_schema.outline;   // Android
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'primary', this.light):
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light)

    this.border_color_ios = this.error ? this.error_color : //this.color_schema_ios.outline;   // iOS
    this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light)


    this.option_active_bg_color = //this.color_schema.surfaceContainer  // only android
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'active_bg_color', 'surfaceContainerHighest', this.light);

    this.menu_bg_color= this.isAndroid? //this.color_schema.surface : this.color_schema_ios.surface
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'menu_bg_color', 'surfaceContainer', this.light)
    :this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'menu_bg_color', 'surfaceContainer', this.light);




}
}
themeColors (drop_style){
  switch (drop_style){
    // 1000
    case MENU_BUTTON_STYLE?.STYLE_01:
      // Outlined
      this.label = this.button.button_label;
      this.inputText =this.button.button_placeholder==null? this.button.button_input: this.button.button_placeholder;

      //special case when there is an input text, the cell should look like active style for Android only
      this.cellHight = this.fontSmaller? this.isAndroid && this.inputText && !this.isActive? '26px': this.settings.cellHight_s:this.isAndroid && this.inputText && !this.isActive? '52px': this.cellHight;


      if (!this.error){
        this.label_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurface', this.light)
      } else {
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'error', this.light)
      }

      this.inputText_color  = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurface', this.light)

    this.leading_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurface', this.light)

    this.trailing_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurface', this.light)
      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;


      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;


      if (!this.error){
        this.border_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light);
      } else {
        this.border_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'error', this.light)
      }
      this.pStyle = 1;
      break;
    case MENU_BUTTON_STYLE?.STYLE_02:
        // outlined without title
        this.label = this.button.button_label;
        this.inputText =this.button.button_placeholder==null? this.button.button_input: this.button.button_placeholder;

      if (!this.error){
        this.label_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurface', this.light)
      } else {
        this.label_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'error', this.light)
      }

      this.inputText_color  = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurface', this.light)

    this.leading_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurface', this.light)

    this.trailing_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurface', this.light)
      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;


      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;

      if (!this.error){
        this.border_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light);
      } else {
        this.border_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'error', this.light)
      }
      this.pStyle=2;

        break;
    case MENU_BUTTON_STYLE?.STYLE_03:
  //filled style
    this.inputText =this.button.button_placeholder==null? this.button.button_input:this.button.button_placeholder;
    this.label = this.button.button_label;

    this.bg_color =  this.isAndroid ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerHighest', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerHighest', this.light)

     this.label_color = this.error ? this.error_color : this.isAndroid ? this.isActive ?
     this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurface', this.light)

   this.inputText_color  = this.isAndroid ? this.isActive ?
     this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurface', this.light)


   this.leading_icon_color = this.isAndroid ? this.isActive ?
     this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurface', this.light)

   this.trailing_icon_color = this.isAndroid ? this.isActive ?
     this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
     : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurface', this.light)

   this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;
   if (!this.error){
    this.border_color = this.isAndroid ? this.isActive ?
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'primary', this.light)
    : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'onSurfaceVariant', this.light)
    : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light);
  } else {
    this.border_color = this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'error', this.light)
  }

     this.pStyle=3;
     break;

      }


}
getTabColor(){
  const appHome = this.indexDBService.getItem("app", "home");
  appHome.onsuccess = (event) => {
    if (appHome.result ) {
     let tabColors =  appHome.result.app.tabs;
      this.active_icon_color_ios = tabColors.active_icon_color_ios;
      this.initialize(this.button.button_style,this.no_buttons)
  }
}
}

get divider(){
return {
  "height": this.cellHight,
  "border-bottom": 'solid 1px',
  "border-color": this.isAndroid? this.border_color: this.border_color_ios,
}
}

get noDivider(){
  return {
    "height": this.cellHight,
  }
  }


get pStyl3() {
  if (this.bg_image) {
    return {
      "background-image": `url(${this.bg_image})`,
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "border-bottom":  this.isActive? 'solid 2px': 'solid 1px',
      "border-color": this.border_color,
      "height": this.cellHight,
    };
  }
  else if (this.start_color && this.end_color) {
    return {
      "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      "border-bottom":  this.isActive? 'solid 2px': 'solid 1px',
      "border-color":this.border_color,
      "height": this.cellHight,
    };
  } else if (this.bg_color) {
    return { background: this.bg_color,
      "border-bottom":  this.isActive? 'solid 2px': 'solid 1px',
      "border-color": this.border_color,
      "height": this.cellHight,

    };
  } else {
    return { background: "unset",
    "height": this.cellHight,
     };
  }
}
get labelColor() {
  return {
    "color": this.label_color,
    'font-size': this.fontSmaller ? '4px' : (this.labelSetting && this.labelSetting.titleSize)? this.labelSetting.titleSize: '14px',
  };
}

get sublabelColor() {
    return {
    "color": this.sublabel_color,
    'font-size': this.fontSmaller ? '3px' : this.no_buttons==3?'10px': (this.trailing_icon && this.leading_icon && this.no_buttons!=1)? '12px': (this.sublabelSetting && this.sublabelSetting.titleSize)? this.sublabelSetting.titleSize: '14px',
  };
}


get inputTextColor() {
  return {
  "color": this.sublabel_color,
  'font-size': this.fontSmaller ? '3px' : this.no_buttons==3?'10px': (this.trailing_icon && this.leading_icon && this.no_buttons!=1)? '12px': (this.sublabelSetting && this.sublabelSetting.titleSize)? this.sublabelSetting.titleSize: '14px',
};
}

get helperColor() {
  return {
  "color": this.helper_color? this.helper_color: '#EBEBEB',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'8px':'10px',
};
}

get errorColor() {
  return {
  "color": this.error_color? this.error_color: '#FF0000',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'10px':'12px',
};
}


get leadingIconColor() {
  return {
  "color": this.leading_icon_color? this.leading_icon_color: '#676767',
  'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'12px':'18px',
};
}

get traillingIconColor() {
  return {
  "color": this.trailing_icon_color? this.isAndroid? this.isActive? this.primary_color: this.trailing_icon_color: '#676767': this.trailing_icon_color,
  'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'12px': this.isAndroid? '18px': '20px',
};
}


get optionSelectedColor() {
  return {
    "color": this.active_icon_color_ios,
    'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'12px': '14px',
  };
}

get borderColor() {
    return {
      "background-color": "solid 1px " + this.primary_color,
      "height": "40px"
    };
  }



get menuBgColor() {
  return {
    "background-color": this.menu_bg_color,
  };
}

get optionColor() {
    return {
      "background-color": this.isAndroid? this.option_active_bg_color: ''
      };
  }

getStyle(style){
  if (this.isAndroid){
    let active = this.isActive? '_A': null;
    if (active){
      return  style + active
    }else return style
  }else
  return style
}


convertStyle (style){
if (style ==MENU_BUTTON_STYLE?.STYLE_1000){
  return MENU_BUTTON_STYLE.STYLE_01;
}

if (style ==MENU_BUTTON_STYLE?.STYLE_2000){
  return MENU_BUTTON_STYLE.STYLE_02;
}

if (style ==MENU_BUTTON_STYLE?.STYLE_3000){
  return MENU_BUTTON_STYLE.STYLE_03;
}

}

ngOnDestroy(): void {
  if (this.updateBuilderComponentSubscription) {
    this.updateBuilderComponentSubscription.unsubscribe();
  }
  if (this.currentMenuSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }


  if (this.colorsComponentSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }


  if (this.offCanvasSubscription) {
    this.offCanvasSubscription.unsubscribe();
  }
}
}



export const album_input_map = {
  [MENU_BUTTON_STYLE?.STYLE_01_A]: {
    settings:{cellHight:'52px',  cellHight_s:'26px',  padding:{default:'px-2 border border-2 rounded-2', 3:'px-1 border border-2 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    options: {padding:{default:'px-3 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting: {padding:{default:'rounded-0', 3:'rounded-0'}, padding_s:'rounded-0',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'p-0', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-2 pt-0 px-2 ', 3:' pb-2 pt-0 px-1'}, padding_s:'pb-0 pt-2 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'38px',  cellHight_s:'15px',  padding:{default:'px-2 border border-2 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    options_ios: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting_ios: {padding:{default:'rounded-3', 3:'rounded-3'}, padding_s:'rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-3 mt-auto mb-auto', 3:'pb-0 pt-0 px-1'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },

  [MENU_BUTTON_STYLE?.STYLE_02_A]: {
    settings:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-2 rounded-2', 3:'px-1 border border-2 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    options: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting: {padding:{default:'rounded-0', 3:'rounded-0'}, padding_s:'rounded-0',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-0 ps-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 px-2', 3:' pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-0 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},


    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-2 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    options_ios: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting_ios: {padding:{default:'rounded-3', 3:'rounded-3'}, padding_s:'rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'px-0 py-2', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },

  [MENU_BUTTON_STYLE?.STYLE_03_A]: {
    settings:{cellHight:'48px',  cellHight_s:'25px',  padding:{default:'px-2', 3:'px-1'}, padding_s:'p-0',},
    options: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting: {padding:{default:'rounded-0', 3:'rounded-0'}, padding_s:'rounded-0',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-1 ps-1 ', titleSize:'12px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-1 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-2 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    options_ios: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting_ios: {padding:{default:'rounded-3', 3:'rounded-3'}, padding_s:'rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },


  [MENU_BUTTON_STYLE?.STYLE_01]: {
    settings:{cellHight:'42px',  cellHight_s:'20px',  padding:{default:'px-2 border border-1 rounded-2', 3:'px-1 border border-1 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    label:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'ps-0 py-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'14px',  titleSize_s:'4px'},
    options: {padding:{default:'px-3 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting: {padding:{default:'rounded-0', 3:'rounded-0'}, padding_s:'rounded-0',},



    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'px-1 py-0', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
    options_ios: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting_ios: {padding:{default:'rounded-3', 3:'rounded-3'}, padding_s:'rounded-2',},


  },
  [MENU_BUTTON_STYLE?.STYLE_02]: {
    settings:{cellHight:'42px',  cellHight_s:'20px',  padding:{default:'px-2 border border-1 rounded-2', 3:'px-1 border border-1 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    label:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'ps-0 py-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'14px',  titleSize_s:'4px'},
    options: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting: {padding:{default:'rounded-0', 3:'rounded-0'}, padding_s:'rounded-0',},


    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'px-1 py-0', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
    options_ios: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting_ios: {padding:{default:'rounded-3', 3:'rounded-3'}, padding_s:'rounded-2',},

  },

  [MENU_BUTTON_STYLE?.STYLE_03]: {
    settings:{cellHight:'52px',  cellHight_s:'26px',  padding:{default:'px-2', 3:'px-1 '}, padding_s:'p-0',},
    options: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting: {padding:{default:'rounded-0', 3:'rounded-0'}, padding_s:'rounded-0',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-2 pt-0 px-2 ', 3:' pb-2 pt-0 px-1'}, padding_s:'pb-0 pt-2 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    options_ios: {padding:{default:'px-2 rounded-0', 3:'px-1 rounded-0'}, padding_s:'p-0 rounded-0',},
    options_setting_ios: {padding:{default:'rounded-3', 3:'rounded-3'}, padding_s:'rounded-2',},

    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},

  },

}













































