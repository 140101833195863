<div class="card h-100">
  <img [src]="bgImage" class="card-img h-100" />
  <div class="card-img-overlay p-0">
    <div [ngStyle]="Colors" class="col-lg-4 rounded-circle m-1 d-flex justify-content-center align-items-center" style="height: 80px; width: 80px;">{{desc}}</div>
    <div [ngStyle]="Colors" class="col-lg-4 d-flex  justify-content-center align-items-center rounded-circle position-absolute  bottom-0 end-0 m-1" style="height: 90px; width: 90px;">
      <i class="dripicons-media-play"></i>
      <div>
      <div class="offcanvas-header-text text-center">Play</div>
      <div class="offcanvas-header-text text-center">video</div>
      </div>
    </div>
  </div>
</div>
