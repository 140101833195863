<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h6 class="m-0 offcanvas-header-text d-flex align-items-center">
          Accept And Continue Button
          <i class="mdi mdi-information-outline font-size-16 ms-1" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"></i>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="text-color" class="offcanvas-header-text w-100" style="text-align: left">
          Button Title
        </label>
        <div class="input-group offcanvas-border-primary border-0 m-0 p-0 rounded-0" title="Using format option">
          <form class="needs-validation w-100" [formGroup]="lableform">
            <input
              type="text"
              id="lablecolor"
              formControlName="lableControl"
              class="form-control input-lg offcanvas-primary border-0"
               (change)="buttonLabel()"
                [ngClass]="{'is-invalid': f?.lableControl?.errors}"
            />
            <div *ngIf="f?.lableControl?.errors" class="invalid-feedback">
              <span *ngIf="f?.lableControl?.errors.required"> Field is required</span>
              <span *ngIf="f?.lableControl?.errors.maxlength"> Actual length is {{f?.lableControl?.errors?.maxlength?.actualLength}} required not more than {{f?.lableControl?.errors?.maxlength?.requiredLength}} chars.</span>
              </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-6">
        <label for="text-color" class="offcanvas-header-text w-100" style="text-align: left">
          Title Color
        </label>
        <div class="input-group offcanvas-border-primary border rounded-2" title="Using format option">
          <input type="text" id="text-color" class="form-control input-lg offcanvas-primary border-0"
            (change)="buttonBgColor('text',$event, true)" [value]="textColor" />
          <span class="input-group-text p-1 bg-transparent border-0 offcanvas-primary">
            <span class="input-group-text colorpicker-input-addon h-100" [cpOutputFormat]="'hex'"
              [style.background]="textColor" [(colorPicker)]="textColor" [cpPosition]="'bottom-left'"
              (colorPickerChange)="buttonBgColor('text',$event, false)">
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label for="text-color" class="offcanvas-header-text w-100" style="text-align: left">
          Background Color
        </label>
        <div class="input-group offcanvas-border-primary border rounded-2" title="Using format option">
          <input type="text" id="text-color" class="form-control input-lg offcanvas-primary border-0"
            (change)="buttonBgColor('bg',$event, true)" [value]="bgColor" />
          <span class="input-group-text p-1 bg-transparent border-0 offcanvas-primary">
            <span class="input-group-text colorpicker-input-addon h-100" [cpOutputFormat]="'hex'"
              [style.background]="bgColor" [(colorPicker)]="bgColor" [cpPosition]="'bottom-left'"
              (colorPickerChange)="buttonBgColor('bg',$event, false)">
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
