<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="googleAdsform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row d-flex justify-content-between align-items-center mb-4">
          <div class="col-10 d-flex align-items-center">
            <h4 class="card-title m-0 offcanvas-header-text d-flex align-items-center">
              Google Ads Settings
              <!-- <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can show or hide the foooter's component at the bottom of your Channel Posts"
              ></i> -->
            </h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3">
            <label
              for="ad_google_key"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Google Ad App ID
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="ad_google_key"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Google Ad App ID"
                formControlName="ad_google_key"
                [ngClass]="{
                  'is-invalid': submitted && f.ad_google_key.errors
                }"
              />
              <div
                *ngIf="submitted && f.ad_google_key.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.ad_google_key.errors.required">
                  Google Ad App ID Required
                </div>
                <div *ngIf="f.ad_google_key.errors.maxlength">
                  {{ "GENERALS.MAXIMUMTITLECHARACTERS" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="ad_native_key"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Google Native Ad ID
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="ad_native_key"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Google Native Ad ID"
                formControlName="ad_native_key"
                [ngClass]="{
                  'is-invalid': submitted && f.ad_native_key.errors
                }"
              />
              <div
                *ngIf="submitted && f.ad_native_key.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.ad_native_key.errors.required">
                  Google Native Ad ID Required
                </div>
                <div *ngIf="f.ad_native_key.errors.maxlength">
                  {{ "GENERALS.MAXIMUMTITLECHARACTERS" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="ad_banner_key"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Google Banner Ad ID
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="ad_banner_key"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Google Banner Ad ID"
                formControlName="ad_banner_key"
                [ngClass]="{
                  'is-invalid': submitted && f.ad_banner_key.errors
                }"
              />
              <div
                *ngIf="submitted && f.ad_banner_key.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.ad_banner_key.errors.required">
                  Google Banner Ad ID Required
                </div>
                <div *ngIf="f.ad_banner_key.errors.maxlength">
                  {{ "GENERALS.MAXIMUMTITLECHARACTERS" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mb-3">
            <label
              class="offcanvas-header-text w-100 m-0"
              for="activateBannerAd"
              style="text-align: left"
            >
              Activate Banner Ad
            </label>
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
                type="checkbox"
                id="activateBannerAd"
                formControlName="disable_banner"
                style="width: 18px; height: 18px"
              />
              <label
                class="offcanvas-header-text w-100 m-0"
                for="activateBannerAd"
              ></label>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label
              class="offcanvas-header-text w-100 m-0"
              for="activateNativeAd"
              style="text-align: left"
            >
              Activate Native Ad
            </label>
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
                type="checkbox"
                id="activateNativeAd"
                formControlName="disable_native"
                style="width: 18px; height: 18px"
              />
              <label
                class="offcanvas-header-text w-100 m-0"
                for="activateNativeAd"
              ></label>
            </div>
          </div>

          <div class="col-12 mb-3">
            <label
              for="adFrequency"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Ad Frequency
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="adFrequency"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Ad Frequency"
                formControlName="cell_count"
                [ngClass]="{ 'is-invalid': submitted && f.cell_count.errors }"
              />
              <div
                *ngIf="submitted && f.cell_count.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.cell_count.errors.required">
                  Ad Frequency Required
                </div>
                <div *ngIf="f.cell_count.errors.maxlength">
                  {{ "GENERALS.MAXIMUMTITLECHARACTERS" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-success w-md ms-auto">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
