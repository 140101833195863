import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-tabsplash-titlehtml",
  templateUrl: "./button-tabsplash-titlehtml.component.html",
  styleUrls: ["./button-tabsplash-titlehtml.component.scss"],
})

export class ButtonTabSplashTitleHtmlComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService:BuilderService
  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() tabData: Tab;

  public Editor = customBuild

  imageConfig: any = {};
  button: MenuButton;
  image: string;
//
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  tab: Tab;
  textColor: string;
  bgColor: string;
  title: string;

  defaultConfig: EditorConfig = {
    fontColor:{
      colors:['#000000', '#808080','#c0c0c0','#ffffff', '#800000', '#ff0000', '#800080', '#ff00ff', '#008000', '#00ff00', '#808000', '#ffff00', '#000080', '#0000ff', '#008080', '#00ffff'],
      colorPicker:{
        format:'hex'
      }
    },
    toolbar: {
      items: ["undo", "redo", "Heading", "bold", "italic",'fontColor'],
    },
    language: "en",
  };


  ngOnInit() {
    this.field_id = 'tabsplashbutton'
    this.sortedButtonList = [];
    this.buttonList = {};

    this.lableform = this.formBuilder.group({
      lableControl: new UntypedFormControl(''),

    });

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {

        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] !== this.component_id
        ) {

          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          let tab = data["splash_tab"]
          this.buttonFunction(tab)


          }
        }
    );
  }

 intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.tabData)
  }

  buttonFunction(tabData){
 if (tabData && tabData.param && tabData.param.splash){
  this.tab= tabData;
  this.title = this.builderService.getTabSplashItem(this.tab.param.splash, 'title',this.tab.param.splash.style);


if (this.title){
  this.lableform = this.formBuilder.group({
    lableControl: new UntypedFormControl(this.title),
  });
  }

}

  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.title= this.f.lableControl.value;
      this.tab.param.splash.title= this.title;
      this.updateButtonData(this.tab)
    }
  }

  get f() {
    if (this.lableform &&  this.lableform.controls) {
      return this.lableform.controls;
    }

  }



  buttonBgColor(item,value) {
    if (item==='text') {
      this.textColor = value
      this.tab.param.splash.button_text_color = this.textColor;
      }
    if (item=== 'bg'){
      this.bgColor =value;
      this.tab.param.splash.button_bgcolor = this.bgColor;
     }
    this.updateButtonData(this.tab)

  }

  updateButtonData(tab?) {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;
    this.data["splash_tab"] = this.tab;


    this.mdw._currentMenuContainer.next(this.data);

if (tab){
  this.mdw.changeTab(
    { button: this.button, tab: this.tab },
    this.mode,
    false
  );

}else {
  this.mdw.changeTab(
    { button: this.button, tab: { module: "menu" } },
    this.mode,
    true
  );
}


  }


  public onReady( editor: customBuild ): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(
      editor.ui.view.toolbar.element!,
      element
    );
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
