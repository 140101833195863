<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BORDERCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="button.button_page_data.border_color"
            (change)="borderColor($event)"
          />
          <span
            class="input-group-text p-1 offcanvas-primary border-0"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="button?.button_page_data?.border_color"
              [(colorPicker)]="button.button_page_data.border_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="borderColor($event)"
            >
            </span>
          </span>
        </div>

    </div>
  </div>
</div>
