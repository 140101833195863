<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h6 class="m-0 offcanvas-header-text d-flex align-items-center">
          Title Font Size
          <i class="mdi mdi-information-outline font-size-16 ms-1" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"></i>
        </h6>
      </div>
    </div>

    <form class="needs-validation" [formGroup]="selectform">
        <div class="mb-2 row p-0 m-0">
          <div class="col-12 p-0 m-0">
            <select class="form-select offcanvas-primary" id="selectoption" formControlName="c7form" (change)="openOption()" >
               <option value="l">Large</option>
               <option value="s">Small</option>
            </select>
          </div>
        </div>
    </form>


      </div>
    </div>
