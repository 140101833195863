import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ImageUploader, Media } from "src/app/core/services/interface";
import { DeleteMyGallery, GetMyMedia } from "src/app/core/services/outbound";
import { WebsocketService } from "src/app/core/services/websocket.service";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { environment } from "src/environments/environment";
import {
  DropzoneComponent,
  DropzoneConfigInterface,
} from "ngx-dropzone-wrapper";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BlobService } from "src/app/sharedservices/blob.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-media-center",
  templateUrl: "./media-center.component.html",
  styleUrls: ["./media-center.component.scss"],
})

/**
 * UI-Image-cropper component
 */
export class MediaCenterComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private blobService: BlobService,
    private mdw: MiddlwareService,
    private _ref: ChangeDetectorRef
  ) {}

  @Input() data: ImageUploader = {
    id: "slider",
    title: "Slider Images",
    max: -1,
    aspectRatio: "1x1",
    resizeToWidth: 500,
    resizeToHeight: 500,
    cropperView: false,
    removePadding: false,
    removeButton: false,
    selectedImages: [],
    hideImagesDisplays: false,
    keyword: "calendar",
  };
  @Input() removePadding = false;
  @ViewChild("largeDataModal") myModal;
  @ViewChild("main", { static: false })
  componentRef?: DropzoneComponent;
  @Output() images = new EventEmitter<any[]>();
  @Output() cancel = new EventEmitter<boolean>(false);
  mediaType: string = "image";
  verifySubscription: any;
  myGallerySubscription: any;
  deleteMyGallerySubscription: any;
  mediaData: Media[] = [];
  mediaPreviewData: Media[] = [];
  mediaSelectedImages: any[] = [];
  selectedImages: any[] = [];
  urlForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;
  component_ref: any;
  mediaList: {};
  pageSize = 24;
  currentPage = 0;
  chat_id: string;
  eop = false;
  showCanvas = true;

  public dropzoneConfig: DropzoneConfigInterface = {
    resizeQuality: 100,
    resizeWidth: 140,
    resizeHeight: 280,
    thumbnailMethod: "crop",
    resizeMethod: "crop",
    acceptedFiles: "image/jpeg,image/pjpeg,image/png",
    addRemoveLinks: true,
    previewsContainer: false,
  };

  uploadimage: any;

  selectedItem: string;
  imageIndex: number;
  opjectImage;

  ngOnInit(): void {
    this.component_ref = this.mdw.makeRef(16);
    this.dropzoneConfig = {
      ...this.dropzoneConfig,
      url: (file) => {
        let url = environment.http_upload_url + "?bin=1&p=1&ct=" + file[0].type;
        if (this.data.aspectRatio) {
          url = url + `&ar=${this.data.aspectRatio}`;
        }
        if (this.data.keyword) {
          url = url + `&kw=${this.data.keyword}`;
        }
        return url;
      },
      method: "put",
      headers: {
        "Cache-Control": false,
        "X-TOKEN": localStorage.getItem("token"),
      },
      resizeWidth: this.data.resizeToWidth,
      resizeHeight: this.data.resizeToHeight,
    };
    this.mediaList = {};
    this.currentPage = 1;
    this.chat_id = localStorage.getItem("chat_id");
    if (this.data) {
      if (this.data.aspectRatio) {
        const aspectNumber = this.data.aspectRatio.split("x");
        this.aspectRatio = Number(aspectNumber[0]) / Number(aspectNumber[1]);
      }
      if (this.data.resizeToWidth) {
        this.resizeToWidth = this.data.resizeToWidth;
        this.dropzoneConfig.resizeWidth = this.data.resizeToWidth;
      }
      if (this.data.resizeToHeight) {
        this.resizeToHeight = this.data.resizeToHeight;
        this.dropzoneConfig.resizeHeight = this.data.resizeToHeight;
      }
      if (this.data.selectedImages && this.data.selectedImages.length > 0) {
        this.selectedImages = [...this.data.selectedImages];
      }

      if (this.data.removeButton) {
        this.collapseMedia = false;
      }

      if (this.data.keyword) {
        this.selectedItem = this.data.keyword;
      }
    }
    this.urlForm = this.formBuilder.group({
      imageUrl: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("(https?://.*.(?:png|jpg))"),
      ]),
    });

    this.myGallerySubscription = this.ws.getMyMediaResponseContainer$.subscribe(
      (myMediaData) => {
        if (myMediaData && myMediaData.ref === this.component_ref) {
          this.currentPage = myMediaData.page_number;
          myMediaData.data.forEach((media: any) => {
            let object: Media;
            if (media.response.media) {
              object = {
                ...media.response.media,
                thumbnail: media.response.thumbnail,
                filename: media.filename,
                id: media.response.file,
              };
            } else if (media.response.url) {
              object = {
                id: media.response.file,
                filename: media.filename,
                url: media.response.url,
              };
            }
            this.mediaList[String(object.filename)] = object;
          });

          this.mediaData = [];
          for (let i = 0; i < this.pageSize; i++) {
            if (
              Object.keys(this.mediaList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ) {
              this.mediaData.push(
                this.mediaList[
                  Object.keys(this.mediaList)[
                    i + (this.currentPage - 1) * this.pageSize
                  ]
                ]
              );
            }
          }

          const pagination_back = document.getElementById("pagination_back");
          const pagination_next = document.getElementById("pagination_next");
          if (pagination_back && pagination_next) {
            pagination_back.classList.remove("d-none");
            pagination_back.classList.add("enablePointer");
            pagination_next.classList.remove("d-none");
            pagination_next.classList.add("enablePointer");

            this.eop = false;
            if (myMediaData.eop) {
              this.eop = true;
              pagination_next.classList.add("d-none");
              pagination_next.classList.remove("enablePointer");
            } else {
              pagination_next.classList.remove("d-none");
              pagination_next.classList.add("enablePointer");
            }
            if (this.currentPage == 1) {
              pagination_back.classList.add("d-none");
              pagination_back.classList.remove("enablePointer");
            } else {
              pagination_back.classList.remove("d-none");
              pagination_back.classList.add("enablePointer");
            }
            // if (this.currentPage == 1 && myMediaData.eop) {
            //   document
            //     .getElementById("showPageNumber")
            //     .classList.add("invisible");
            // } else {
            //   document
            //     .getElementById("showPageNumber")
            //     .classList.remove("invisible");
            // }
          }
        }
      }
    );

    this.deleteMyGallerySubscription =
      this.ws.deleteMyGalleryContainer$.subscribe((deletemyGalleryData) => {
        if (deletemyGalleryData) {
          this.mediaData = this.mediaData.filter(
            (image) => image.id !== deletemyGalleryData.filename
          );
        }
      });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData && this.data.removeButton) {
          this.ws.processMethod(
            new GetMyMedia(
              this.component_ref,
              0,
              this.mediaType,
              this.chat_id,
              this.data.aspectRatio,
              this.data.keyword
            )
          );
        }
      }
    );
  }

  ngAfterViewInit(): void {
    document.getElementById("pagination_back").classList.add("d-none");
    document
      .getElementById("pagination_back")
      .classList.remove("enablePointer");
    document.getElementById("pagination_next").classList.add("d-none");
    document
      .getElementById("pagination_next")
      .classList.remove("enablePointer");

    this.componentRef.directiveRef
      .dropzone()
      .on("success", (file, serverResponce) => {
        const media: Media = {
          ...serverResponce.media,
          filename: serverResponce.file,
          thumbnail: serverResponce.thumbnail,
          id: serverResponce.file,
        };
        this.mediaList[file.upload.uuid] = media;
        this.mediaData = [];
        this.mediaData = Object.values(this.mediaList);
        this._ref.detectChanges();
      });
  }

  get f() {
    return this.urlForm.controls;
  }

  base64ToFile(data, filename) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  addImages(image, status) {
    if (status) {
      const id = this.mdw.makeRef(16);
      const mediaData = {
        id,
        media: image,
      };
      if (this.data.max == 1) {
        this.mediaSelectedImages = [mediaData];
      } else {
        if (
          this.data.max !=
          this.selectedImages.length + this.mediaSelectedImages.length
        ) {
          this.mediaSelectedImages.push(mediaData);
        }
      }
    } else {
      this.mediaSelectedImages = this.mediaSelectedImages.filter(
        (img) => img.media.id !== image.id
      );
    }
  }

  useSelected() {
    this.selectedImages = [...this.selectedImages, ...this.mediaSelectedImages];
    this.mediaSelectedImages = [];
    if (!this.data.removeButton) {
      this.collapseMedia = !this.collapseMedia;
    }
    this.images.emit(this.selectedImages);
  }

  deleteMyGallarySelected() {
    this.mediaSelectedImages.map((image: any) => {
      this.ws.processMethod(new DeleteMyGallery(image.media.id));
    });
    this.mediaSelectedImages = [];
  }

  deleteSelected(img) {
    this.selectedImages = this.selectedImages.filter(
      (image) => image.id !== img.id
    );
    this.images.emit(this.selectedImages);
  }

  addImageUrl() {
    this.submitted = true;
    if (this.urlForm.invalid) {
    } else {
      var filename = this.urlForm.value.imageUrl.split("/").pop();
      const id = this.mdw.makeRef(16);
      const media: Media = {
        filename: filename,
        permanentUrl: this.urlForm.value.imageUrl,
      };
      const mediaData = {
        id,
        media: media,
      };
      this.selectedImages.push(mediaData);
      this.images.emit(this.selectedImages);
      this.urlForm.reset();
      this.submitted = false;
    }
  }

  isImageSelected(status: boolean) {
    if (status) {
      return {
        border: "2px solid",
      };
    }
  }


  isRetchTheMax() {
    if (
      this.data.max != 1 &&
      this.data.max ==
        this.selectedImages.length + this.mediaSelectedImages.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  async onFileAdded(event, modal) {
    // if(event.status == 'success'){
    const file = event.target.files[0];
    // Encode the file using the FileReader API
    if (file != null) {
      const blopFile = await this.blobService.createBlobFile(file);
      const blopUrl = await this.blobService.getblobURL(blopFile);
      this.blobService.getImageMetadata(blopUrl).then((imageMetadata: any) => {
        this.orgFile = file;
        if (!this.aspectRatio) {
          this.aspectRatio = imageMetadata.width / imageMetadata.height;
        }
        if (imageMetadata.height >= imageMetadata.width) {
          if (this.resizeToHeight == 0) {
            if (file.height > 512) {
              this.resizeToHeight = 512;
            } else {
              this.resizeToHeight = imageMetadata.height;
            }
          }
        } else {
          if (this.resizeToWidth == 0) {
            if (imageMetadata.width > 512) {
              this.resizeToWidth = 512;
            } else {
              this.resizeToWidth = imageMetadata.width;
            }
          }
        }
        this.largeModal(modal);
      });
    }
    // }
  }

  imageCropped(event) {
    this.file = {
      file: event.blob,
      width: event.width,
      height: event.height,
      type: event.blob.type,
    };
  }

  imageLoaded(event) {}

  largeModal(largeDataModal: any) {
    this.modalRef = this.modalService.show(largeDataModal, {
      class: "modal-lg",
    });
  }

  isMediaSelected(media) {
    return (
      this.mediaSelectedImages.filter((res) => res.media.id === media.id)
        .length > 0
    );
  }

  nextPage() {
    this.ws.processMethod(
      new GetMyMedia(
        this.component_ref,
        this.currentPage,
        this.mediaType,
        this.chat_id,
        this.data.keyword
      )
    );
  }

  backPage() {
    if (this.currentPage != 1) {
      this.currentPage--;
      this.mediaData = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (
          Object.keys(this.mediaList)[
            i + (this.currentPage - 1) * this.pageSize
          ]
        ) {
          this.mediaData.push(
            this.mediaList[
              Object.keys(this.mediaList)[
                i + (this.currentPage - 1) * this.pageSize
              ]
            ]
          );
        }
      }
      if (this.currentPage == 1) {
        document.getElementById("pagination_back").classList.add("d-none");
        document
          .getElementById("pagination_back")
          .classList.remove("enablePointer");
      }
      if (
        this.currentPage <
        Object.keys(this.mediaList).length / this.pageSize
      ) {
        document.getElementById("pagination_next").classList.remove("d-none");
        document
          .getElementById("pagination_next")
          .classList.add("enablePointer");
      }
    }
  }

  uploadeImages(file: any) {}

  async filesAdd(files) {
    let opject = {};
    if (Array.isArray(files)) {
      for (let i = 0; i < files.length; i++) {
        const blopFile = await this.blobService.createBlobFile(files[i]);
        const blopUrl = await this.blobService.getblobURL(blopFile);
        const opjectImage = {
          id: files[i].upload.uuid,
          filename: files[0].name,
          status: "upload",
          permanentUrl: blopUrl,
        };
        opject[opjectImage.id] = opjectImage;
      }
      this.mediaList = { ...opject, ...this.mediaList };
      this.mediaData = Object.values(this.mediaList);
    } else {
      const blopFile = await this.blobService.createBlobFile(files[0]);
      const blopUrl = await this.blobService.getblobURL(blopFile);
      const opjectImage = {
        id: files[0].upload.uuid,
        filename: files[0].name,
        status: "upload",
        permanentUrl: blopUrl,
      };
      opject[opjectImage.id] = opjectImage;
      this.mediaList = { ...opject, ...this.mediaList };
      this.mediaData = Object.values(this.mediaList);
    }
  }

  showOffcanvas() {
    const sideNave = document.querySelector("#side-nave");
    if (sideNave.classList.contains("animate-go-in")) {
      sideNave.classList.add("animate-leave-out");
      sideNave.classList.remove("animate-go-in");
      this.showCanvas = false;
    } else if (sideNave.classList.contains("animate-leave-out")) {
      sideNave.classList.remove("animate-leave-out");
      sideNave.classList.add("animate-go-in");
      this.showCanvas = true;
    }
  }

  getMediaBykeyword(keyword) {
    this.selectedItem = keyword;
    this.mediaList = {};
    this.ws.processMethod(
      new GetMyMedia(
        this.component_ref,
        0,
        this.mediaType,
        this.chat_id,
        this.data.aspectRatio,
        keyword
      )
    );
  }

  openSlaider(event, image, index) {
    event.stopPropagation();
    const carousel = document.querySelector("#carouselExampleCaptionDiv");
    const scroll = document.querySelector("#scroll");
    this.imageIndex = index;
    this.mediaPreviewData = [...this.mediaData];
    this._ref.detectChanges();
    carousel.classList.add("animate-right-in");
    carousel.classList.remove("animate-right-out");
    scroll.classList.add("w-50");
  }
  closeSlaider(event) {
    event.stopPropagation();
    this.mediaPreviewData=[];
    const carousel = document.querySelector("#carouselExampleCaptionDiv");
    const scroll = document.querySelector("#scroll");
    carousel.classList.remove("animate-right-in");
    carousel.classList.add("animate-right-out");
    scroll.classList.remove("w-50");
  }

  cancelMedia() {
    this.cancel.emit();
  }

  ngOnDestroy(): void {
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.myGallerySubscription) {
      this.myGallerySubscription.unsubscribe();
    }
    if (this.deleteMyGallerySubscription) {
      this.deleteMyGallerySubscription.unsubscribe();
    }
  }
}
