<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="verificationform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row d-flex justify-content-between align-items-center mb-4">
          <div class="col-10 d-flex align-items-center">
            <h4 class="card-title m-0 offcanvas-header-text d-flex align-items-center">
              Store Verification Account
              <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can show or hide the foooter's component at the bottom of your Channel Posts"
              ></i>
            </h4>
          </div>
        </div>
        <ng-container *ngIf="!demoAccData">
          <div class="col-12 mb-3">
            <label
              for="country"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Country
            </label>
            <select
              class="form-select offcanvas-primary"
              id="country"
              formControlName="country"
              (change)="selectCountry()"
            >
              <option
                *ngFor="let country of mobCountries"
                [value]="
                  country?.dial + ',' + country?.isoCode2 + ',' + country?.name
                "
              >
                {{ country?.name }}
              </option>
            </select>
          </div>
          <div class="col-12 mb-3" *ngIf="page_id === login_types.EMAIL">
            <label
              for="email"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Email
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="email"
                formControlName="email"
                [ngClass]="{
                  'is-invalid': submitted && f.email.errors
                }"
                placeholder="Enter Email"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.email.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3" *ngIf="page_id === login_types.MSISDN">
            <label
              for="msisdn"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Mobile
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <div class="d-flex align-items-center">
                <input
                  type="text"
                  class="form-control input-lg border-0 offcanvas-primary w-25 me-2"
                  formControlName="dailCode"
                  [ngClass]="{
                    'is-invalid': submitted && f.dailCode.errors
                  }"
                  readonly
                />
                <input
                  type="text"
                  class="form-control input-lg border-0 offcanvas-primary w-75"
                  id="msisdn"
                  formControlName="msisdn"
                  [ngClass]="{
                    'is-invalid': submitted && f.msisdn.errors
                  }"
                  placeholder="Enter Mobile"
                />
              </div>
              <div
                *ngIf="submitted && f.msisdn.errors"
                class="invalid-feedback text-start"
              >
                <div *ngIf="f.msisdn.errors.required">
                  Mobile Number Required
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-success w-md">Verify</button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="demoAccData">
          <div class="col-12 mb-3">
            <label for="type" class="offcanvas-header-text w-100" style="text-align: left">
              Account Type
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="type"
                formControlName="type"
                readonly
                [ngClass]="{
                  'is-invalid': submitted && f.type.errors
                }"
                placeholder="Enter Email"
              />
              <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.type.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="email"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Email Address
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="email"
                formControlName="email"
                readonly
                [ngClass]="{
                  'is-invalid': submitted && f.email.errors
                }"
                placeholder="Enter Email"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.email.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="Country"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Country
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="Country"
                formControlName="countryName"
                readonly
                [ngClass]="{
                  'is-invalid': submitted && f.countryName.errors
                }"
                placeholder="Enter Email"
              />
              <div
                *ngIf="submitted && f.countryName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.countryName.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.countryName.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="text-color"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Android & IOS Instructions
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                Open App
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                Tap "ACCEPT AND CONTINUE"
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                Tap "Allow" to allow notifications permission
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                Under the country code select: "The country"
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                Enter the following phone number: "xxxxxxx"
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                Tap "Next"
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                App will open and you will have full access
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                The app will open granting you all permissions.
              </p>
              <p class="mb-1 w-100 text-start offcanvas-header-text">
                <i
                  class="mdi mdi-circle-medium align-middle offcanvas-header-text me-1"
                ></i>
                To delete your account go to Settings -> Account-> Delete my
                account.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-success w-md"
                (click)="remove()"
              >
                Remove
              </button>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>

<ng-template #verifyAccount let-modal>
  <div class="modal-body p-4">
    <div class="text-center">
      <!-- <div class="avatar-md mx-auto">
        <div class="avatar-title rounded-circle bg-light">
          <i class="fab fa-apple h1 mb-0 text-primary"></i>
        </div>
      </div> -->
      <div class="p-2 mt-4">
        <h4>Verify your Account</h4>
        <p class="mb-5">
          Please enter the verification code sent to the email or mobile
          entered.
        </p>
        <form>
          <ng-otp-input
            [config]="config"
            (onInputChange)="onOtpChange($event)"
          ></ng-otp-input>
        </form>

        <div class="mt-4">
          <alert type="danger" *ngIf="wrongOTP" [dismissible]="false">
            You should enter the whole 6-digit code before submit
          </alert>
        </div>
        <p class="mb-5">
          Didn't receive the verification code? <a class="card-link me-1" (click)="resendTak()">Resend Code</a> <span id="seconds"></span>
        </p>

        <div class="mt-4">
          <button
            (click)="registerDemoAccount()"
            class="btn btn-success w-md enablePointer"
            type="button"
            [disabled]="inValid"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
