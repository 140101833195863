import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { TabsetComponent, TabDirective } from "ngx-bootstrap/tabs";
import {
  LOGIN_TYPES,
  PaymentProviderIDs,
  TabNames,
  c1,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-offcanvas-gen-settings",
  templateUrl: "./offcanvas-gen-settings.component.html",
  styleUrls: ["./offcanvas-gen-settings.component.scss"],
})
export class OffcanvasGenSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private ref: ChangeDetectorRef,
    private builderService: BuilderService
  ) {}

  offcanvas_data: any = [];
  offCanvasSubscription: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "gen";

  page_id: string;
  title: string;
  hideoffcanvas: boolean;
  current_tab: string;
  active_tabset_index: number = 0;
  tab: Tab;
  updateBuilderComponentSubscription: any;
  data: any;
  login_types = LOGIN_TYPES;
  paymentProviderIDs = PaymentProviderIDs;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        this.data = data;
        this.OffCanvasData(data);
      }
    );

    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data && data.tab && data.offcanvasRefersh) {
          // offcanvasRefresh prevents flickering and prevent the component (offcanvas) to update itself and then components flikers.
          // only update from outside component can send updated data to the offcanvas.
          this.OffCanvasData(this.data);
        }
      });
  }

OffCanvasData(data){
  if (data) {
    if (data.off_canvas_key === "gen") {
      this.page_id = data.page.id;
      this.current_tab = data.tab;
      this.title = data.page.title;
      this.hideoffcanvas = true;

      switch (data.page.id) {
        case "nav_tab":

          this.tab = data.tab;   // to fire after changes
          this.active_tabset_index= localStorage.getItem("mode")[0]==c1.ANDRIOD? 0: 1;  // to select initial opening setting tab.

          this.offcanvas_data = [
            {
              tab: "ANDROID",
              id: c1.ANDRIOD,
              icon:"fab fa-android",
              active:this.activeTabSetting(data.page.id,0),

              data: [
                {
                  cat: "Basic",
                  show: false,
                  list: [{ anavtab: true},  {anavtabstyle:true}, {anavcolor:false},],
                }
                ],
            },
            {
              tab: "iOS",
              id: c1.IOS,
              icon:"fab fa-apple",
              active: this.activeTabSetting(data.page.id,1),
              data: [
                {
                  cat: "Basic",
                  show: false,
                  list: [  {inavinfo:true},{inavtabstyle:true}, {inavcolor:false},],
                }
                ],
            }

          ];
          break;
        case "nav_side":
            this.tab = data.tab;   // to fire after changes
            this.active_tabset_index= localStorage.getItem("mode")[0]==c1.ANDRIOD? 0: 1;  // to select initial opening setting tab.

            this.offcanvas_data = [
              {
                tab: "ANDROID",
                id: c1.ANDRIOD,
                icon: "fab fa-android",
                active: this.activeTabSetting(data.page.id, 0),

                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                      { anavtab: true },
                      { anavtabstyle: true },
                      { anavcolor: true },
                    ],
                  },
                ],
              },
              {
                tab: "iOS",
                id: c1.IOS,
                icon: "fab fa-apple",
                active: this.activeTabSetting(data.page.id, 1),
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                      { inavinfo: true },
                      { inavtabstyle: true },
                      { inavcolor: false },
                    ],
                  },
                ],
              },
            ];
            break;
        // case "nav_side":
        //     this.tab = data.tab; // to fire after changes
        //     this.active_tabset_index =
        //       localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

        //     this.offcanvas_data = [
        //       {
        //         tab: "ANDROID",
        //         id: c1.ANDRIOD,
        //         icon: "fab fa-android",
        //         active: this.activeTabSetting(data.page.id, 0),
        //         data: [
        //           {
        //             cat: "Basic",
        //             show: false,
        //             list: [{ asidetoggle: true }, { asidecolor: true }],
        //           },
        //         ],
        //       },
        //       {
        //         tab: "iOS",
        //         id: c1.IOS,
        //         icon: "fab fa-apple",
        //         active: this.activeTabSetting(data.page.id, 1),
        //         data: [
        //           {
        //             cat: "Basic",
        //             show: false,
        //             list: [
        //               { isidetoggle: true },
        //               { iside: true },
        //               { isideinfo: true },
        //             ],
        //           },
        //         ],
        //       },
        //     ];

        //     break;
          case this.login_types.EMAIL:
            this.tab = data.tab; // to fire after changes
            this.active_tabset_index =
              localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

            this.offcanvas_data = [
              {
                tab: "ANDROID",
                id: c1.ANDRIOD,
                icon: "fab fa-android",
                active: this.activeTabSetting(data.page.id, 0),
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                      { buttonapploginmessagehtml: true },
                      { buttonsmtpserver: true },
                      { buttonAllowUsertoChangeEmail: true },
                      { buttonAppLoginverificationaccount: true },
                    ],
                  },
                ],
              },
            ];
            break;
          case this.login_types.MSISDN:
            this.tab = data.tab; // to fire after changes
            this.active_tabset_index =
              localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

            this.offcanvas_data = [
              {
                tab: "ANDROID",
                id: c1.ANDRIOD,
                icon: "fab fa-android",
                active: this.activeTabSetting(data.page.id, 0),
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                      { buttonapploginmessagehtml: true },
                      { buttonAppLoginVendorsMobile: true },
                      { buttonAppLoginverificationaccount: true },
                    ],
                  },
                ],
              },
            ];
            break;
          case this.login_types.NO_LOGIN:
            this.hideoffcanvas = false;
            this.offcanvas_data = [];
            break;
          case this.paymentProviderIDs.STRIPE:
            this.tab = data.tab; // to fire after changes
            this.active_tabset_index =
              localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

            this.offcanvas_data = [
              {
                tab: "ANDROID",
                id: c1.ANDRIOD,
                icon: "fab fa-android",
                active: this.activeTabSetting(data.page.id, 0),
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ buttonAppPaymentStripe: true }],
                  },
                ],
              },
            ];
            break;
          case this.paymentProviderIDs.PAYPAL:
            this.tab = data.tab; // to fire after changes
            this.active_tabset_index =
              localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

            this.offcanvas_data = [
              {
                tab: "ANDROID",
                id: c1.ANDRIOD,
                icon: "fab fa-android",
                active: this.activeTabSetting(data.page.id, 0),
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ buttonAppPaymentPaypal: true }],
                  },
                ],
              },
            ];
            break;
            case this.paymentProviderIDs.INAPPGOOGLE:
              this.tab = data.tab; // to fire after changes
              this.active_tabset_index =
                localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

              this.offcanvas_data = [
                {
                  tab: "ANDROID",
                  id: c1.ANDRIOD,
                  icon: "fab fa-android",
                  active: this.activeTabSetting(data.page.id, 0),
                  data: [
                    {
                      cat: "Basic",
                      show: false,
                      list: [{ buttonAppPaymentInappPurchases: true }],
                    },
                  ],
                },
                {
                  tab: "iOS",
                  id: c1.IOS,
                  icon: "fab fa-apple",
                  active: this.activeTabSetting(data.page.id, 1),
                  data: [
                    {
                      cat: "Basic",
                      show: false,
                      list: [],
                    },
                  ],
                },
              ];
              break;
            case this.paymentProviderIDs.GOOGLEPROVIDER:
              this.tab = data.tab; // to fire after changes
              this.active_tabset_index =
                localStorage.getItem("mode")[0] == c1.ANDRIOD ? 0 : 1; // to select initial opening setting tab.

              this.offcanvas_data = [
                {
                  tab: "ANDROID",
                  id: c1.ANDRIOD,
                  icon: "fab fa-android",
                  active: this.activeTabSetting(data.page.id, 0),
                  data: [
                    {
                      cat: "Basic",
                      show: false,
                      list: [{ buttonAppPaymentGoogleAdsAndroid: true }],
                    },
                  ],
                },
                {
                  tab: "iOS",
                  id: c1.IOS,
                  icon: "fab fa-apple",
                  active: this.activeTabSetting(data.page.id, 1),
                  data: [
                    {
                      cat: "Basic",
                      show: false,
                      list: [{buttonAppPaymentGoogleAdsIOS: true}],
                    },
                  ],
                },
              ];
              break;
          default:
            {
              this.hideoffcanvas = true;
            }
            break;
        }
        if (this.hideoffcanvas && data.show) {
          this.showOffcanvas();
          this.hideoffcanvas = false;
        } else {
          this.hideOffcanvas();
        }
        this.off_canvas_key = data.off_canvas_key;
      } else {
        this.hideOffcanvas();
      }
    }
  }
  // another way to set the active tab. without markign the html with [active] tag
  // https://stackblitz.com/edit/ngx-bootstrap-tabs-manual?file=app%2Fapp.component.ts
  // goto(id){
  //   this.tabset.tabs[id].active = true;
  // }

  activeTabSetting(pageId, tabIndex) {
    if (pageId === this.page_id) {
      if (tabIndex === this.active_tabset_index) {
        return true;
      } else return false;
    } else return false;
  }

  tabSetChange(event) {
    // only active to trigger on certian page components
    switch (this.page_id) {
      case "nav_tab":
      case "nav_side":
        this.setMobileThemeC1(event);

        break;
    }
  }

  setMobileThemeC1(event) {
    let modes: any;
    if (event.id !== localStorage.getItem("mode")[0]) {
      // only if there is a change we will broadcast. This will prevent looping between the two components which are switching views
      // they are doing ping pong as the changes detected using TabSelect()

      if (event.id == c1.IOS) {
        modes = [{ id: c1.ID, value: c1.IOS }];
      } else {
        modes = [{ id: c1.ID, value: c1.ANDRIOD }];
      }
      this.mdw.changeMobileTheme(modes);
      let data = { ...{ tab: this.tab } };

      this.mdw._updateBuilderComponentContainer.next(data);
    }
  }

  hideOffcanvas() {
    document
      .querySelector("#offcanvasScrollingGenConf")
      .classList.remove("show");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrollingGenConf").classList.add("show");
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
  }
}
