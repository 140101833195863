import { TabsModule } from "ngx-bootstrap/tabs";
import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIModule } from "./ui/ui.module";
import { SellingchartComponent } from "./sellingchart/sellingchart.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImagecropperComponent } from "./imagecropper/imagecropper.component";
import { ChipsComponent } from "./chips/chips.component";
import { HelpModelComponent } from "./help-model/help-model.component";
import { HttpClientModule } from "@angular/common/http";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { MediaDurationPipe } from "./pipes/media-duration.pipe";
import { BuilderComponentModule } from "../builder-component/builder-component.module";
import { TabSliderComponent } from "./tab-slider/tab-slider.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { FullCalendarsComponent } from "./full-calendar/full-calendar.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { CountSizePipe } from "./pipes/count-size.pipe";
import { ClickElsewhereDirective } from "./directives/click-elsewhere.directive";
import { LocationInputComponent } from "./location-input/location-input.component";
import { GoogleMapsModule } from "@angular/google-maps";

@NgModule({
  declarations: [
    SellingchartComponent,
    ImagecropperComponent,
    ChipsComponent,
    HelpModelComponent,
    FileSizePipe,
    MediaDurationPipe,
    TabSliderComponent,
    FullCalendarsComponent,
    CountSizePipe,
    ClickElsewhereDirective,
    LocationInputComponent
  ],
  imports: [
    TabsModule.forRoot(),
    CommonModule,
    UIModule,
    NgApexchartsModule,
    ImageCropperModule,
    BuilderComponentModule,
    UiSwitchModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    TimepickerModule.forRoot(),
    GoogleMapsModule
  ],
  exports: [
    SellingchartComponent,
    ImagecropperComponent,
    ChipsComponent,
    HelpModelComponent,
    HttpClientModule,
    FileSizePipe,
    MediaDurationPipe,
    TabSliderComponent,
    FullCalendarsComponent,
    CountSizePipe,
    ClickElsewhereDirective,
    LocationInputComponent
  ]
})
export class SharedModule {}
