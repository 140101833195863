import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-poll-info-component",
  templateUrl: "./tab-poll-info-component.component.html",
  styleUrls: ["./tab-poll-info-component.component.scss"],
})
export class TabPollInfoComponentComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  description: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  modalRef?: BsModalRef;
  storeForm: UntypedFormGroup;
  imageConfig: any = {};

  ngOnInit() {
    this.storeForm = this.formBuilder.group({
      title: new UntypedFormControl(""),
    });

    this.imageConfig = {
      id: "",
      title: "Image",
      max: 1,
      aspectRatio: "1x1",
      resizeToWidth: 370,
      resizeToHeight: 370,
      cropperView: false,
      selectedImages: [],
      removeButton: true,
      imageType: "image/jpeg,image/pjpeg,image/png",
      imageSet: false,
      keyword: "store",
      mode: ["upload"],
      params: "",
    };
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");
          this.imageConfig["id"] = this.tab.id;

          if (this.tab.title) {
            this.title = this.tab.title;
            this.storeForm.patchValue({ title: this.tab.title });
          }
        }
      }
    );
  }

  titleChange(event) {
    this.tab.title = this.storeForm.value.title;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
