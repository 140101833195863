import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-sublabel-html",
  templateUrl: "./button-sublabel-html.component.html",
  styleUrls: ["./button-sublabel-html.component.scss"],
})

export class ButtonSubLabelHtmlComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  public Editor = customBuild
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_sublabel: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"

  ngOnInit() {
    this.sortedButtonList = [];
    this.buttonList = {};


    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {

          this.buttonIntialize(data)

          if (this.button.button_sublabel) {
            this.button_sublabel = this.button.button_sublabel;

          }
          // if (this.button.button_label_color) {
          //   this.labelColor = this.button.button_label_color;
          // }  // Hazem to fix

          this.lableform = this.formBuilder.group({
            lableControl: new UntypedFormControl(this.button_sublabel,),
          });

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&& this.button_id &&
          data.button.button_id == this.button_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

        }
      }
    );
  }


  buttonLabel() {
    if (this.f.lableControl.valid){
      this.button.button_sublabel = this.f.lableControl.value;
      this.button_sublabel = this.f.lableControl.value;
      this.updateButtonData()
    }
     }


get f(){
      if (this.lableform.controls){
        return this.lableform.controls;
      }
   }


  buttonIntialize(data)
  {

          this.button = { ...data.button };
          this.data = data.data;

          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          if (data.button.button_id == this.button_id) {
            this.button = { ...data.button };
          }
          this.mode = localStorage.getItem("mode");


  }

  updateButtonData(){
    //*must be there in all buttons

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );

    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
