<div class="card m-0 bg-transparent">
  <div class="card-body">


    <div class="row">

      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BORDERSIZE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
        <input type="range" [value]="button?.button_page_data?.border_size" class="form-range" min="1" max="5" step="1" id="size" #size (change)="changeSize(size.value)">

        </div>
      </div>

  </div>
  </div>
</div>
