<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row m-0">
      <form class="needs-validation w-100 p-0" [formGroup]="labelform">
        <div
          class="form-check d-flex align-items-center justify-content-between flex-row-reverse p-0 mb-3"
        >
          <input
            type="checkbox"
            id="newPage"
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            style="width: 18px; height: 18px"
            formControlName="newPage"
            (change)="updateNewPage()"
          />
          <div>
            <label for="newPage" class="text-white m-0">New Screen</label>
            <p class="text-muted m-0">Open next action in New Screen.</p>
          </div>
        </div>
        <div
          class="form-check d-flex align-items-center justify-content-between flex-row-reverse p-0"
        >
          <input
            type="checkbox"
            id="buttondb"
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            style="width: 18px; height: 18px"
            formControlName="buttondb"
            (change)="updatebuttondb()"
          />
          <div>
            <label for="buttondb" class="text-white m-0">Menu Cache</label>
            <p class="text-muted m-0">Enable menu caching.</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
