<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form [formGroup]="storeForm">
        <app-image-uploader
          [data]="imageConfig"
          (images)="storeImage($event)"
        ></app-image-uploader>
        <div class="mb-3">
          <label class="offcanvas-header-text w-100" style="text-align: left">
            Title
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="title"
              #tabTitle
              class="form-control input-lg offcanvas-primary border-0"
              formControlName="title"
              (change)="titleChange(tabTitle?.value)"
            />
          </div>
        </div>
        <div class="mb-3">
          <label for="description"> Description </label>
          <div class="input-group offcanvas-border-primary border rounded-2">
            <textarea
              class="form-control offcanvas-primary border-0"
              id="description"
              #tabDescription
              formControlName="description"
              rows="6"
              [placeholder]="'Description'"
              (change)="descriptionChange(tabDescription?.value)"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
