<div class=" not-allowed ">
<!-- ********* Android Active **************** -->
<div *ngIf="isAndroid">
<div *ngIf="isActive && isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0" >
  <!-- Default -->
  <fieldset class="d-flex align-item-center m-0 " [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'">
    <legend class="float-none w-auto m-0 p-0" [ngClass]="labellPadding" [ngStyle]="labelColor">
      {{label}}
    </legend>
    <div class="row m-0 p-0 d-flex justify-content-center w-100 align-item-center" [ngClass]="fontSmaller?'p-0':'p-1'">
    <div *ngIf="leading_icon_flag" class="col-1 m-0 p-0 d-flex justify-content-center" >
      <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
      </i> -->
      <i  class="float-none w-auto p-0 m-0 " [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(activeOption_icon).type=='mir'?'material-icons-outlined':'material-icons'">
        {{ builderService.getIcon(activeOption_icon).id }}
      </i>
    </div>
    <div  [ngClass]="leading_icon_flag? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="  m-0 p-0  d-flex justify-content-start" >
      <p class=" m-0 text-wrap w-auto" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
        {{inputText }}
     </p>
    </div>
    <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
      <!-- // for Text fields -->
      <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="inputText Padding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
        {{ builderService.getIcon(trailing_icon).id }}
      </i> -->
    <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding" class=" {{trailing_icon}}" >
     </i>

    </div>
    </div>
  </fieldset>
</div>

<div *ngIf=" isActive&&  isAndroid && pStyle==2" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'">
    <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
      <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
        </i> -->
        <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="m-0 p-0  d-flex justify-content-start" >
          <p class="float-none w-auto m-0" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
           {{inputText}}
        </p>
      </div>
      <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
        </i> -->


        <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i> -->

        <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding" class=" {{trailing_icon}}" >
        </i>

      </div>
    </div>
    </fieldset>
</div>

<div *ngIf=" isActive&&  isAndroid && pStyle==3" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center" [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset class="d-flex align-items-center flex-column m-0 rounded-top-2" [ngClass]="padding" [ngStyle]="pStyl3" >
<div class="row m-0 p-0 w-100  d-flex justify-content-center align-items-center" [ngClass]="fontSmaller?'p-0':'p-1'" >
  <div class="col-1 m-0 px-0  d-flex justify-content-center"  *ngIf="leading_icon" >
    <!-- <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  class="p-0 mt-auto mb-auto">
    </i> -->
    <i class="float-none w-auto  m-0 p-0 mt-auto mb-auto" [ngStyle]="leadingIconColor"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
      {{ builderService.getIcon(leading_icon).id }}
    </i>
  </div>
  <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="m-0 p-0  d-flex justify-content-start flex-column">
    <p class="float-none w-auto  m-0 d-flex justify-content-start" [ngStyle]="labelColor"  [ngClass]="labellPadding">
      {{label}}
    </p>
    <p class=" m-0 text-wrap d-flex justify-content-start " [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
       {{inputText}}
    </p>
  </div>

  <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
    <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="labellPadding">
    </i> -->
    <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
      {{ builderService.getIcon(trailing_icon).id }}
    </i> -->

    <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding" class=" {{trailing_icon}}" >
    </i>

  </div>
</div>
    </fieldset>
</div>
<!-- ********* add the menu drop here only in case of active cell -->
<div *ngIf="isActive" class="position-relative m-0 p-0">
  <div class=" card m-0 p-0 shadow-sm m-0 rounded" [ngStyle]="menuBgColor" [style]="' border-color: ' + border_default_color + ' !important'" [ngClass]="fixedTop?'position-absolute fixed-top':''" [ngClass]="optionCardPadding">
    <div   *ngFor="let option of options; index as index;" >

      <!-- no divider -->
      <div *ngIf="(index == noOptions-1) || !option.divider" class="row m-0 p-0 w-100" [style]="noDivider" [ngStyle]="activeOption && (option.id===activeOption?.id)? optionColor: null"[ngClass]="optionPadding">
        <div *ngIf="leading_icon_flag"  class="col-1 m-0 p-0 d-flex justify-content-center align-items-center"  >
           <i  class="float-none w-auto  m-0 px-2 " [ngStyle]="leadingIconColor"  [class]="builderService.getIcon(option.icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(option.icon).id }}
          </i>
        </div>
        <div [ngClass]="leading_icon_flag? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="  m-0 p-0  d-flex justify-content-start align-items-center " >
          <p class=" text-wrap w-auto m-0 px-2" [ngStyle]="inputTextColor"  >
            {{option.label}}
         </p>
        </div>
        <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center" >
        </div>

      <!-- <div *ngIf="true && index < noOptions-1 " class="m-0 p-0"
        [style]="'border-bottom: solid 1px; border-color: ' + border_color + ' !important'">
      </div> -->

      </div>
   <!-- with diver -->
   <div *ngIf="!((index == noOptions-1) || !option.divider)" class="row m-0 p-0 w-100" [style]="divider" [ngStyle]="activeOption && (option.id===activeOption?.id)? optionColor: null"[ngClass]="optionPadding">
    <div *ngIf="leading_icon_flag"  class="col-1 m-0 p-0 d-flex justify-content-center align-items-center"  >
       <i  class="float-none w-auto  m-0 px-2 " [ngStyle]="leadingIconColor"  [class]="builderService.getIcon(option.icon).type=='mir'?'material-icons-outlined':'material-icons'">
        {{ builderService.getIcon(option.icon).id }}
      </i>
    </div>
    <div [ngClass]="leading_icon_flag? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="  m-0 p-0  d-flex justify-content-start align-items-center " >
      <p class=" text-wrap w-auto m-0 px-2" [ngStyle]="inputTextColor"  >
        {{option.label}}
     </p>
    </div>
    <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center" >
    </div>

  <!-- <div *ngIf="true && index < noOptions-1 " class="m-0 p-0"
    [style]="'border-bottom: solid 1px; border-color: ' + border_color + ' !important'">
  </div> -->

  </div>
    </div>
    </div>
</div>
<!-- ******************************************* -->
</div>
  <!-- ********* Android Default  **************** -->
  <div *ngIf="!isActive && isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <div class="m-0 p-0">
      <fieldset class="d-flex align-items-center m-0 p-0" [ngClass]="padding" [style]="'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'">
        <legend  *ngIf="inputText" class="float-none w-auto m-0" [ngClass]="labellPadding" [ngStyle]="labelColor">
          {{label}}
        </legend>

        <div class="row m-0 p-1  d-flex justify-content-center w-100">
          <div   *ngIf="leading_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
            <!-- <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
            </i> -->
            <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
              {{ builderService.getIcon(leading_icon).id }}
            </i>
          </div>
          <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex  justify-content-start" >
            <p *ngIf="!inputText" class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
              {{label}}
            </p>
            <p *ngIf="inputText" class="float-none w-auto  m-0" [ngStyle]="inputTextColor"  [ngClass]="labellPadding">
              {{inputText}}
            </p>
            </div>
            <div   *ngIf="trailing_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
              <!-- <i class="float-none w-auto  m-0" [ngStyle]="traillingIconColor" class="{{trailing_icon}}"  [ngClass]="labellPadding">
              </i> -->
              <!-- <i class="float-none w-auto  m-0" [ngStyle]="traillingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
                {{ builderService.getIcon(trailing_icon).id }}
              </i> -->


              <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="labellPadding" class=" {{trailing_icon}}" >
              </i>

            </div>

        </div>
      </fieldset>
    </div>
    </div>

<div *ngIf="!isActive  && isAndroid && pStyle==2" class="row  rounded-1 m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'" >
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'">

    <div  class="row m-0 p-1 d-flex  justify-content-center w-100">
      <div  *ngIf="leading_icon" class="col-1 m-0 px-0 d-flex justify-content-center" >
        <!-- <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
        </i> -->
        <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="m-0 p-0 d-flex justify-content-start">
        <p *ngIf="!inputText" class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
          {{label}}
        </p>
        <p *ngIf="inputText" class="float-none w-auto  m-0" [ngStyle]="inputTextColor"  [ngClass]="labellPadding">
          {{inputText}}
        </p>
      </div>

      <div   *ngIf="trailing_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
        <!-- <i class="float-none w-auto  m-0" [ngStyle]="traillingIconColor" class="{{trailing_icon}}"  [ngClass]="labellPadding">
        </i> -->
        <!-- <i class="float-none w-auto  m-0" [ngStyle]="traillingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(trailing_icon).id }}
        </i> -->

        <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="labellPadding" class=" {{trailing_icon}}" >
        </i>
      </div>
    </div>
  </fieldset>
</div>

<div *ngIf="!isActive  && isAndroid && pStyle==3" class="row  rounded-1 m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset  class="d-flex align-items-center m-0 rounded-top-2" [ngClass]="padding" [ngStyle]="pStyl3" >
      <div class="row m-0 p-1  d-flex justify-content-center w-100">
        <div   *ngIf="leading_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
          <!-- <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
          </i> -->
          <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(leading_icon).id }}
          </i>
        </div>
        <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="m-0 p-0 d-flex justify-content-start flex-column" >
          <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
            {{label}}
          </p>
          <p *ngIf="inputText" class="float-none w-auto  m-0" [ngStyle]="inputTextColor"  [ngClass]="labellPadding">
            {{inputText}}
          </p>
       </div>
        <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
          <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="labellPadding">
          </i> -->
          <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
            {{ builderService.getIcon(trailing_icon).id }}
          </i> -->

          <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="labellPadding" class=" {{trailing_icon}}" >
          </i>

        </div>
      </div>
    </fieldset>
  </div>


<!-- ********* iOS Active and Default **************** -->

<div *ngIf="!isAndroid">

  <div *ngIf="!isAndroid && pStyle==1" class="row m-0 p-0 ">
  <div class="row d-flex align-item-center m-0 p-0 ">
    <div class="col-12 m-0 p-0">
      <p class="float-none w-auto  m-0" [ngStyle]="labelColor" [ngClass]="labellPadding">
        {{label}}
      </p>
    </div>
    <div class="col-12 m-0 p-0 bg-white rounded-3">
        <fieldset class="d-flex align-item-center m-0" [ngClass]="padding"
          attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color_ios +  '!important' }}">
          <div class="row m-0 p-0 d-flex align-item-center w-100">
            <div class ="col-11  m-0 p-0 d-flex align-item-center">
            <p class=" ms-0 text-wrap" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
              {{inputText}}
            </p>
              </div>
              <div class="col-1 m-0 p-0 d-flex justify-content-center align-item-center" >
                  <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
                  </i> -->


                  <!-- Test fields -->
                  <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
                    {{ builderService.getIcon(trailing_icon).id }}
                  </i> -->

                  <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding" class=" {{trailing_icon}}" >
                  </i>
              </div>
          </div>
        </fieldset>
    </div>
    </div>
  </div>


  <div *ngIf="!isAndroid && pStyle==2" class="row m-0 p-0 ">
    <div class="row d-flex align-item-center m-0 p-0 ">
      <div class="col-12 m-0 p-0">
        <!-- <p class="float-none w-auto  m-0" [ngStyle]="labelColor" [ngClass]="labellPadding">
          {{label}}
        </p> -->
      </div>
      <div class="col-12 m-0 p-0 bg-white rounded-3">
          <fieldset class="d-flex align-item-center m-0" [ngClass]="padding"
            attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color_ios +  '!important' }}">
            <div class="row m-0 p-0 d-flex align-item-center w-100">
              <div class ="col-11  m-0 p-0 d-flex align-item-center">
                <p *ngIf="!inputText"  class=" ms-4 text-wrap" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
                  {{label}}
                </p>
                <p class=" ms-0 text-wrap" *ngIf="inputText" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
                {{inputText}}
              </p>
                </div>
                <div class="col-1 m-0 p-0 d-flex justify-content-center align-item-center" >
                    <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
                    </i> -->


                    <!-- Test fields -->
                    <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
                      {{ builderService.getIcon(trailing_icon).id }}
                    </i> -->

                    <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding" class=" {{trailing_icon}}" >
                    </i>
                </div>
            </div>
          </fieldset>
      </div>
      </div>
    </div>
  <div *ngIf="!isAndroid && pStyle==3" class="row m-0 p-0 d-flex align-item-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset class="d-flex align-item-center m-0 bg-white" [ngClass]="padding" [style]="'border-bottom: solid 1px; height:' +  cellHight + '; border-color:' + border_color_ios +  '!important'">
      <div class="row m-0 p-0 d-flex align-item-center w-100">
        <div class ="m-0 p-0 col-2 d-flex align-item-center">
      <p class="float-none w-auto m-0 text-wrap" *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
        {{label}}
      </p>
    </div>
    <div class="col-9 m-0 p-0 d-flex align-item-center">
      <p class="float-none w-auto m-0 text-wrap" *ngIf="inputText"  [ngStyle]="inputTextColor" [ngClass]="sublabelPadding">
        {{inputText}}
        </p>
    </div>

    <div class="col-1 m-0 p-0 d-flex justify-content-center align-item-center" >
      <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
      </i> -->
      <!-- <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding"  [class]="builderService.getIcon(trailing_icon).type=='mir'?'material-icons-outlined':'material-icons'">
        {{ builderService.getIcon(trailing_icon).id }}
      </i> -->

      <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" [ngClass]="sublabelPadding" class=" {{trailing_icon}}" >
      </i>
  </div>
     </div>
        </fieldset>
  </div>

  <div *ngIf="isActive">
    <div class="position-relative">
      <div class=" card m-0 p-0 shadow-lg m-0 rounded" [style]="'border-color: ' + border_default_color + ' !important'" [ngClass]="fixedTop?'position-absolute fixed-top':''" [ngClass]="optionCardPadding" >
        <div   *ngFor="let option of options; index as index;" >
              <div *ngIf="(index == noOptions-1) || !option.divider" class="row m-0 w-100 " [style]="noDivider" [ngStyle]="activeOption&& (option.id==activeOption.id)? optionColor: null" [ngClass]="optionPadding">
                  <div *ngIf="1!=1" class="col-1 m-0 d-flex justify-content-center align-items-center"  >
                    <!-- <i class="float-none w-auto  m-0 px-2 " [ngStyle]="leadingIconColor" class="{{option.icon}}" >
                    </i> -->
                    <!-- <i class="float-none w-auto  ms-2 m-0 px-2 " [ngStyle]="leadingIconColor"  [class]="builderService.getIcon(option.icon).type=='mir'?'material-icons-outlined':'material-icons'">
                      {{ builderService.getIcon(option.icon).id }}
                    </i> -->
                  </div>
                  <div [ngClass]="1!=1? leading_icon_flag? 'col-10':'col-11':  leading_icon_flag?'col-11':'col-12'" class="  m-0 p-0  d-flex justify-content-start align-items-center " >

                    <i class="float-none w-auto  m-0 px-1 not-allowed"  [ngClass]="activeOption &&(option.id==activeOption?.id)?optionSelected_ios: 'me-3'" >
                    </i>
                    <p class=" m-0 text-wrap w-auto p-0" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding" >
                      {{option.label}}
                  </p>

                  </div>
                  <div *ngIf="leading_icon_flag" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center" >
                    <!-- <i class="float-none w-auto  m-0 " [ngStyle]="optionSelectedColor" class="{{option_selected_ios}}">
                   </i> -->

                    <i class="float-none w-auto  ms-2 m-0 px-2 " [ngStyle]="leadingIconColor"
                         [class]="builderService.getIcon(option.icon).type=='mir'?'material-icons-outlined':'material-icons'">
                         {{ builderService.getIcon(option.icon).id}}
                    </i>


                  </div>
              </div>
              <div *ngIf="!((index == noOptions-1) || !option.divider)" class="row m-0 w-100 " [style]="divider" [ngStyle]="activeOption&& (option.id===activeOption?.id)? optionColor: null" [ngClass]="optionPadding">
                <div *ngIf="1!=1" class="col-1 m-0 d-flex justify-content-center align-items-center"  >
                  <!-- <i class="float-none w-auto  m-0 px-2 " [ngStyle]="leadingIconColor" class="{{option.icon}}" >
                  </i> -->
                  <!-- <i class="float-none w-auto  ms-2 m-0 px-2 " [ngStyle]="leadingIconColor"  [class]="builderService.getIcon(option.icon).type=='mir'?'material-icons-outlined':'material-icons'">
                    {{ builderService.getIcon(option.icon).id }}
                  </i> -->
                </div>
                <div [ngClass]="1!=1? leading_icon_flag? 'col-10':'col-11':  leading_icon_flag?'col-11':'col-12'" class="  m-0 p-0  d-flex justify-content-start align-items-center " >

                  <i class="float-none w-auto  m-0 px-1 not-allowed"  [ngClass]="activeOption && (option.id===activeOption?.id)?optionSelected_ios: 'me-3'" >
                  </i>
                  <p class=" m-0 text-wrap w-auto p-0" [ngStyle]="inputTextColor" [ngClass]="sublabelPadding" >
                    {{option.label}}
                </p>

                </div>
                <div *ngIf="leading_icon_flag" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center" >
                  <!-- <i class="float-none w-auto  m-0 " [ngStyle]="optionSelectedColor" class="{{option_selected_ios}}">
                 </i> -->

                  <i class="float-none w-auto  ms-2 m-0 px-2 " [ngStyle]="leadingIconColor"
                       [class]="builderService.getIcon(option.icon).type=='mir'?'material-icons-outlined':'material-icons'">
                       {{ builderService.getIcon(option.icon).id}}
                  </i>


                </div>
            </div>
          </div>
        </div>
    </div>
  </div>

 </div>

  <div *ngIf="helper || error" class="ps-1" >
    <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
    {{helper}}
    </p>
    <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
      {{error}}
      </p>
  </div>
</div>































