<!-- <div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row mb-3" class="offcanvas-divider">
      <div class="col-6 d-flex justify-content-end  mb-3">
        <label
        for="text-color"
        class="offcanvas-header-text w-100"
        style="text-align: left"
      >

      </label>
      </div>
      <div class="col-6 d-flex justify-content-end  mb-3">
        <button type="button" class="btn btn-primary inner" (click)="addNewMarker()">Add</button>
      </div>
    </div>
    <div class="mb-3" *ngFor="let marker of button_page_data.map_markers;let index = index" class="offcanvas-divider">

    <div class="row mb-1">

      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.LATITUDE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format markerion"
        >
          <input


            type="text"
            id="lat"
            #lat
            class="form-control input-lg offcanvas-primary border-0"
            [value]="marker.position.lat"
            (change)="changeMarker(lat.value,lng.value, index)"
          />
        </div>
      </div>


      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.LONGITUDE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format markerion"
        >
          <input
            type="text"
            id="lng"
            #lng
            class="form-control input-lg offcanvas-primary border-0"
            [value]="marker.position.lng"
            (change)="changeMarker(lat.value,lng.value, index)"
          />
        </div>
      </div>
    </div>
<div class="row mb-1">
  <div class="col-12 d-flex justify-content-end ">
  <button  class="btn btn-default icon text-danger" (click)="removeMarker(index)"><i  class="mdi mdi-delete font-size-18"></i></button>
  </div>
    </div>
  </div>
  </div>
</div> -->

<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="card-header bg-transparent p-0 mb-3">
      <h6 class="offcanvas-header-text text-start">{{   'GENERALS.MARKERS' | translate}}</h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let marker of button_page_data.map_markers;">
          <div
            class="card offcanvas-primary mb-2"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selecteSittings"
            *ngIf="
              !selecteSittings ||
              (selecteSittings && selecteSittings.id !== marker.id)
            "
            style="cursor: move"
          >
            <div class="row m-0 no-gutters align-items-center">
              <div class="col-md-12 p-0">
                <div class="card-body card-btn-toggle position-relative ps-0">
                  <p class="card-text text-start offcanvas-header-text m-0 ps-3">{{ marker.label ? marker.label : 'New Item' }}</p>
                  <button
                    class="btn btn-primary offcanvas-header-text p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 40px;
                      transform: translateY(-50%);
                    "
                    (click)="openSettings(marker)"
                  >
                    <i class="fas fa-cog font-size-14"></i>
                  </button>
                  <button
                    class="btn btn-danger offcanvas-header-text p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 5px;
                      transform: translateY(-50%);
                    "
                    (click)="deleteImage(marker.id)"
                  >
                    <i class="mdi mdi-delete font-size-14"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card bg-transparent bg-opacity-50 mb-2"
            cdkDrag
            *ngIf="selecteSittings && selecteSittings.id === marker.id"
            style="border: 1px solid;"
          >
            <div class="card-body">
              <div class="mb-3  text-start">
                <label   for="text-color"
                class="offcanvas-header-text w-100"
                style="text-align: left">   {{   'GENERALS.LABEL' | translate}}</label>
                <input
                        type="text"
                        id="markerTitle"
                        #markerTitle
                        class="form-control input-lg offcanvas-primary border-0"
                        [value]="marker.label"
                      />

              </div>
              <div class="mb-3">

                <label   for="text-color"
                class="offcanvas-header-text w-100"
                style="text-align: left">    {{   'GENERALS.LATITUDE' | translate}}</label>
                <input
                        type="text"
                        id="markerTitle"
                        #markerLat
                        class="form-control input-lg offcanvas-primary border-0"
                        [value]="marker.position.lat"
                      />

              </div>
              <div class="mb-3">
                <label
                for="text-color"
                class="offcanvas-header-text w-100"
                style="text-align: left"
              >
              {{   'GENERALS.LONGITUDE' | translate}}
              </label>
                  <input
                    type="text"
                    id="markerDesc"
                    #markerLng
                    class="form-control input-lg offcanvas-primary border-0"
                    [value]="marker.position.lng"
                  />
                </div>
              <div
                class="d-flex flex-wrap justify-content-between align-content-center gap-2"
              >
                <button
                  type="submit"
                  class="btn btn-secondary"
                  (click)="openSettings(null)"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveChanges(markerTitle.value,markerLng.value, markerLat.value)"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row m-0 mb-2" *ngIf="!selecteSittings">
      <button
        class="btn btn-secondary shadow-none col-6"
        type="button"
        (click)="addNewSwaper()"
      >
        Add New Item
      </button>
    </div>
</div>

