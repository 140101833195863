import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { c1, c10, c5 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-navdrawerbar",
  templateUrl: "./navdrawerbar.component.html",
  styleUrls: ["./navdrawerbar.component.scss"],
})
export class NavDrawerBarComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) { }

  @Input() fontResize = false;
  @Input() fontSmaller = false;
  @Input() tab_id;

  updateBuilderComponentSubscribtion: any;
  colorsComponentSubscription: any;
  // default values

  tabs: Tab[] = [];
  colors: any = {};
  home;
  bg_color: string =null;
  start_color: string = null// = "#1be6e2";
  end_color: string = null//= "#1be6e2";

  text_color: string;
  isAndroid: string;
  active_icon_color: string
  active_title_color: string
  default_color: string
  currentTabSubscription: any;
  viewSubscription: any;
  sortedTabList: any =[];
  tabList: any;
  cat: string;
  current_tab_id: any;
  data: any;
  mode: string;
  current_tab: string;
  updateBuilderComponentSubscription: any;
  wide: boolean;
  theme: any;
  makeItSmaller: boolean;
  c1_Android: string = c1.ANDRIOD
  c1_Ios: string =c1.IOS
  color_schema: any;
  color_schema_ios: any;
  light: boolean = true;
  bg_image: string = null
  album_button: any;
  albums: any;
  emptyImage: string;
  settings: string;
  emptyImageP0: string;
  emptyImageP1: string;
  widthP0: string;
  image: string =null;
  styleP0: number = 0;
  label_color: string;
  sublabel_color: string;
  textAlign: any = {};
  textFontsize: any = {};
  bgTop_color: string;
  labelTop_color: string;

  ngOnInit() {
    this.mode = localStorage.getItem("mode");
    this.data = {};
    this.data["sortedTabList"] = [];
    this.data["tabList"] = {};
    try {
      this.mobileTabBarInitialize()
    } catch (error) {
      if (localStorage.getItem("ndbx")) {
        setTimeout(async () => {
          this.mobileTabBarInitialize()
        }, 2000);
      } else {
        this.mobileTabBarInitialize()
      }
    }

    this.updateBuilderComponentSubscription =  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {
        this.mobileTabBarInitialize()
        this.navDrawerInitialize()

        this.wide = localStorage.getItem("mode")[0]==c1.ANDRIOD?
        localStorage.getItem("mode")[4]==c5.WIDE? true: false :
        localStorage.getItem("mode")[9]==c10.WIDE? true: false;

  }});

      this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (data && data["current_tab"] && data["current_tab"].cat =="side") {

          console.log("NavDrawer", data);

          this.data = data;
          this.sortedTabList = structuredClone(data["sortedTabList"]);
          this.current_tab_id = data ["current_tab"].id;

          let index = this.sortedTabList.findIndex((e) => e.id == '0');

           if (index!== -1){
            this.sortedTabList.splice(index, 1);
          }

          this.navDrawerInitialize()
        }
      })

      this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
        (data) => {
          if (data) {
            this.colors = data;
            this.light = data.light
            this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
            this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
            this.colorIntialiaze();
            this.navDrawerInitialize()
          }
        })


    }


    navDrawerInitialize(){
      this.album_button = album_button_map;
      this.albums = this.album_button.albums
      this.settings = this.album_button.settings;

      this.emptyImageP0 = this.albums[0].emptyImage[1];
      this.emptyImageP1 = this.albums[1].emptyImage[1];
      this.widthP0 = this.albums[0].width[1];
    }
  mobileTabBarInitialize() {
    this.isAndroid = localStorage.getItem("mode")[0];
    this.theme = this.mdw.mobileTheme()
    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = (event) => {
      if (appHome.result) {
        this.colors.color_schema = appHome.result.app.tabs.color_schema;
        this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
        this.colorIntialiaze();

  }
  }}
  colorIntialiaze(){
    if (this.isAndroid === c1.ANDRIOD) {
      this.bgTop_color = this.color_schema.m2SurfaceContainerHigh;
      this.labelTop_color = this.color_schema.m2OnSurfaceContainerHigh;

      this.bg_color = this.color_schema.m2BSurface;
      this.label_color = this.color_schema.m2BOnSurface;

      this.active_title_color = this.color_schema.m2BOnSurface;
      this.active_icon_color = this.color_schema.m2BOnSurface;

    } else if (this.isAndroid === c1.IOS) {
      this.bg_color = this.color_schema_ios.onPrimary;
      this.label_color = this.color_schema_ios.surfaceVariant;

      this.bgTop_color = this.color_schema.onPrimary;
      this.labelTop_color = this.color_schema.surfaceVariant;

      this.active_title_color = this.color_schema_ios.primary;
      this.active_icon_color = this.color_schema_ios.primary;
}

  }
    titleStyle(tab_id) {
      if (tab_id === this.current_tab_id &&  this.bg_color &&  this.active_title_color ) {
         return {  color: this.active_title_color  };
      } else if (tab_id !== this.current_tab_id &&  this.bg_color &&  this.label_color) {
        return { color: this.label_color };
      }
    }


   iconStyle(tab_id) {
      if (tab_id === this.current_tab_id &&  this.bg_color &&  this.active_icon_color ) {
        return {  color: this.active_icon_color  };
      } else if (tab_id !== this.current_tab_id &&  this.bg_color &&  this.label_color) {
        return { color: this.label_color };
      }
    }


  get backColors() {
    if (this.colors) {
      return {    background: this.bg_color };
    }
  }

  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  tabTrackBy(index, tab){
    return tab.tab_version;
  }


  get setButtonBg() {

    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return {
        background: this.bg_color,
        color: this.label_color,
       };
    } else {
      return { background: "unset" };
    }
  }


  get setTopPartBg() {
if (this.bgTop_color) {
      return {
        background: this.bgTop_color,
        color: this.label_color,
      };
    } else {
      return { background: "unset" };
    }
  }
  get lableColor() {
    return {
      "color": this.label_color,
      // 'font-size': this.fontSmaller ? '5px' : (this.settings && this.settings.titleSize) ? this.settings.titleSize : '12px',
      'font-size': this.fontSmaller ? '5px' : this.fontResize? this.fontScale('headline', '12px'): this.fontScale('headline', '16px'),
    };
  }

  get sublableColor() {
    return {
      "color": this.sublabel_color,
      // 'font-size': this.fontSmaller ? '3px' : '9px',
      'font-size': this.fontSmaller ? '3px' : this.fontResize? this.fontScale('subhead', '10px'): this.fontScale('subhead', '14px'),

    };
  }


  fontScale(item, value) {
    let result: number;

    result = value.replace("px", "");

    // large is greater +2 and smaller is less -2
    if (this.textFontsize && this.textFontsize[item]) {

      switch (this.textFontsize[item]) {
        case 'sm':
          result = Number(result) - 2;
          return result + 'px'
          break;
        case 'md':
          return Number(result) + 'px'
          break;
        case 'lg':
          result = Number(result) + 2;
          return result + 'px'
          break;
      }
    } else {
      return value
    }
  }
  ngOnDestroy() {

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }

    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}

export const album_button_map = {
albums: [
  {
    id: 0,
    en: true,
    emptyImage: {
      1: "./assets/images/mobile/emptyImageSideTop.png",
    },
    padding: 0,
    opacity: 0,
    blur: "0px",
    round: "rounded-0",
    width: { 1: "100%", 2: "100%", 3: "100%" },
  },
  {
    id: 1,
    en: true,
    emptyImage: {
      1: "./assets/images/mobile/emptyImageSideBottom.png",
    },
    padding: 0,
    opacity: 0,
    blur: "0px",
    round: "rounded-0",
  },
],
}
