<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h6 class="m-0 offcanvas-header-text d-flex align-items-center">
          Navigation Drawer Colors
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"
          ></i>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          Burger Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            (change)="colorsUpdate('burger_icon_color',$event)"
            [value]="colors?.burger_icon_color"
            [disabled]="!enableSideMenu"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="colors?.burger_icon_color"
              [(colorPicker)]="colors.burger_icon_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorsUpdate('burger_icon_color',$event)"
              [cpDisabled]="!enableSideMenu"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          Drawer Icon Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            (change)="colorsUpdate('side_menu_icon',$event)"
            [value]="colors?.side_menu_icon "
            [disabled]="!enableSideMenu"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="colors?.side_menu_icon"
              [(colorPicker)]="colors.side_menu_icon"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorsUpdate('side_menu_icon',$event)"
              [cpDisabled]="!enableSideMenu"
            >
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
