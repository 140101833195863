import {
  Directive,
  EventEmitter,
  ElementRef,
  HostListener,
  Output,
  Input
} from '@angular/core';

@Directive({ selector: '[appClickElsewhere]' })
export class ClickElsewhereDirective {
  @Input() clickId: string;
  @Output() clickElsewhere = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    // Check if the click was outside the element
    if (this.clickId && this.clickId !== targetElement.id) {
      this.clickElsewhere.emit(event);
    } else if (
      !this.clickId&&
      targetElement &&
      !this.elementRef.nativeElement.contains(targetElement)
    ) {
      this.clickElsewhere.emit(event);
    }
  }
}
