<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
         {{   'GENERALS.VIDEOURL' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="url"
            #url
            class="form-control input-lg offcanvas-primary border-0"
            [value]="videoUrl"
            (change)="videoUrlChange(url?.value)"
          />

        </div>
      </div>

    </div>
    <div class="row mb-3">

      <div class="col-12">
        <label for="bg-color" class="offcanvas-header-text w-100" style="text-align: left">
          {{   'GENERALS.DESCRIPTION' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="desc"
            #desc
            class="form-control input-lg offcanvas-primary border-0"
         [value]="videoDesc"
            (change)="videoUrlChange(desc?.value)"
          />

        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.TEXTCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="textColor"

            (change)="colorTextPickerChange($event)"
          />
          <span
            class="input-group-text p-1 offcanvas-primary border-0"

          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="textColor"
              [(colorPicker)]="textColor"

              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorTextPickerChange($event)"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label
          for="bg-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BGCOLOR' | translate}}
        </label>
        <div
        class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="bg-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="bgColor"

            (change)="colorBgPickerChange($event)"
          />
          <span
          class="input-group-text p-1 offcanvas-primary border-0"

          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="bgColor"
              [(colorPicker)]="bgColor"

              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorBgPickerChange($event)"
            >
            </span>
          </span>
        </div>
      </div>


    </div>
  </div>
</div>
