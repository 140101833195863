import { MENU } from "./../sidebar/menu";
import { UsersModule } from "./../../pages/users/users.module";
import { webInfo, Menu } from "./../../core/services/interface";
import { filter } from "rxjs/operators";
import { EventService } from "./../../core/services/event.service";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Message, Page } from "src/app/core/services/interface";
import { GetAppConfig, GetPoll, GetStore, SelectChat, SetMStore, SetPoll } from "src/app/core/services/outbound";
import { environment } from "src/environments/environment";
import {
  TabNames,
  c1,
  colorSchemaDefault,
  message_status_map,
} from "src/app/core/services/constants";
import { BuilderService } from "src/app/sharedservices/builder.service";
import { LoaderService } from "src/app/core/services/loader.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  profileSubscription: any;
  getBalanceSubscription: any;
  adminSubscription: any;
  superAdminSubscription: any;
  chatListSubscription: any;
  selectedChatSubscription: any;
  messageSubscription: any;
  messageListSubscription: any;
  userListSubscription: any;
  menuPageResponseSubscription: any;
  requestAppleCodeResponseSubscription: any;
  getPollMethodSubscription: any;
  getPollSubscription: any;
  likeMessageAckSubscription: any;
  colorsComponentSubscription: any;
  verifySubscription: any;
  appBuilderSubscription: any;
  schemeChangeSubscription: any;
  getAppConfigOnceSubscription: any;
  storeSubscription: any;
  pollSubscription: any;
  getStoreSubscription: any;
  getStoreMethodSubscription: any;
  profile_name: any;
  profile_image: any = "assets/images/users/account.webp";
  mode: any = "light";
  balance: any;
  admin = false;
  super = false;
  chatList = [];
  main_chat: any;
  default_chat: any;
  showChatList = false;
  isAndroid = true;
  colors: any;
  scheme: any;
  component_ref: any;
  component_id: string = "topbar"
  inValid = true;
  otp = null;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px",
    },
  };



  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private ws: WebsocketService,
    private es: EventService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    private indexDBService: IndexDBService,
    private ref: ChangeDetectorRef,
    private load: LoaderService
  ) { }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.openMobileMenu = false;
    this.showChatList = false;
    this.element = document.documentElement;

    this.colors = colorSchemaDefault;
    this.changeTheme();

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.profileSubscription = this.ws.getMyProfileContainer$.subscribe(
      (profileData) => {
        if (profileData != null) {
          this.profile_image = profileData.data.profile_image;
          this.profile_name = profileData.data.profile_name;
        }
      }
    );
    this.getBalanceSubscription = this.ws.getBalanceContainer$.subscribe(
      (balanceData) => {
        // console.log(balanceData);
        if (balanceData) {
          this.balance = balanceData.data.balance;
        }
      }
    );
    this.adminSubscription = this.mdw.showAdminContainer$.subscribe((data) => {
      this.admin = data;
      // console.log(this.admin);
    });
    this.superAdminSubscription = this.mdw.showRootAdminContainer$.subscribe(
      (data) => {
        this.super = data;
        // console.log(this.super);
      }
    );
    this.chatListSubscription = this.ws.getChatListContainer$.subscribe(
      (data) => {
        if (data) {
          this.chatList = data.filter((chat) => chat.type === "Channel");
          this.default_chat = this.chatList.find(
            (e: any) => e.id == localStorage.getItem("chat_id")
          );
          this.showChatList = true;
          this.indexDBService.updateItemList("chats", this.chatList);
          let list = {};
          console.log(data);
          for (let i = 0; data.length > i; i++) {
            if (data[i]) {
              list[data[i].id] = data[i];
            }
          }
          this.mdw._chatList.next(list);
        }
      }
    );
    this.selectedChatSubscription = this.ws.selectedChatContainer$.subscribe(
      (selectedChatData) => {
        if (selectedChatData) {
          if (this.chatList) {
            this.default_chat = this.chatList.find(
              (e: any) => e.id == selectedChatData
            );
          }
        }
      }
    );
    this.messageSubscription = this.ws.messageTagsContainer$.subscribe((message) => {
      if (message) {
        if (
          !message.message.welcome &&
          !message.message.schedule &&
          !message.message.award
        ) {
          if (!message.status) {
            message.status = "text";
          }
          if (!message.message.reply_to_message_id) {
            message.message.reply_to_message_id = "0";
          }
          if (message.message.from.id && message.message.sent_to && message.message.sent_to.id && message.message.from_admin == 1) {
            message.message.user = message.message.sent_to.id;
          } else if (message.message.from.id && (!message.message.from_admin || message.message.from_admin == 0)) {
            message.message.user = message.message.from.id;
          }

          this.saveToDB(message, true, true);
        }
      }
    });
    this.messageListSubscription = this.ws.messageListResponeContainer$.subscribe((messages) => {
      if (messages) {
        let mediaTypes = ["photo", "video", "gif"];
        let mediaCount = 0;
        for (let i = 0; i < messages.length; i++) {
          if (
            !messages[i].message.welcome &&
            !messages[i].message.schedule_date &&
            !messages[i].message.award
          ) {
            if (!messages[i].status) {
              messages[i].status = "text";
            }
            if (!messages[i].message.reply_to_message_id) {
              messages[i].message.reply_to_message_id = "0";
            }

            if (messages[i].message.from.id && messages[i].message.sent_to && messages[i].message.sent_to.id && messages[i].message.from_admin == 1) {
              messages[i].message.user = messages[i].message.sent_to.id;
            } else if (messages[i].message.from.id && (!messages[i].message.from_admin || messages[i].message.from_admin == 0)) {
              messages[i].message.user = messages[i].message.from.id;
            }

            if (mediaTypes.includes(messages[i].message.type)) {
              mediaCount++;
              if (mediaCount > 10) {
                this.saveToDB(messages[i], false, false);
              } else {
                this.saveToDB(messages[i], false, true);
              }
            } else {
              this.saveToDB(messages[i], false, false);
            }
            if(messages[i].message.inline_menu && messages[i].message.inline_menu.length > 0){
              this.getPoll(messages[i].message.inline_menu[0].menu_group);
            }
          }
        }
        // console.log("sent");
      }
    });
    this.menuPageResponseSubscription = this.ws.menuPageResponseContainer$.subscribe((data) => {
      if (data) {
        this.builderService.savePage(data);
      }
    });

    this.userListSubscription = this.ws.listUsersContainer$.subscribe((listUsersData) => {
      if (listUsersData) {
        for (let i = 0; i < listUsersData.members.length; i++) {
          // console.log(listUsersData.members[i].member_id);
          const user_message = this.indexDBService.getItemList("user", "user", listUsersData.members[i].member_id);
          user_message.onsuccess = () => {
            // console.log("USER MESSAGE GET ITEM LIST");
            // console.log(user_message?.result);
            if (user_message.result && user_message.result.length > 0) {
              let userMsg = user_message.result;
              userMsg.forEach((msg) => {
                msg.message.from["name"] = listUsersData.members[i].name;
                msg.message.from["url"] = listUsersData.members[i].media.photo.url;
                this.indexDBService.updateItem("user", msg);
                this.mdw._userSavedNotifyContainer.next({ ...msg, ref: listUsersData.ref });
              });
            }
          };
        }
      }
    });

    this.likeMessageAckSubscription = this.ws.likeMessageAckContainer$.subscribe((data) => {
      if (data) {
        const message = this.indexDBService.getItem("messages", data.message_id);
        let newMessage;
        message.onsuccess = (event) => {
          if (message.result) {
            newMessage = { ...message.result };
            newMessage.myLike = data.myLike;
            this.indexDBService.updateItem("messages", newMessage).onsuccess = (event) => {
              newMessage.message.status = message_status_map.UPDATED;
              this.ws._incomingMessageContainer.next(newMessage);
            };
          }
        }
      }
    });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(async (verifyData) => {
      if (verifyData) {
        this.ws.processMethod(new GetStore(0));
        this.ws.processMethod(new GetAppConfig(this.component_ref));
      }
    });

    this.getAppConfigOnceSubscription = this.mdw.getAppConfigOnceContainer$.subscribe((data) => {
      if (data) {
        console.log("LAODING FALSE");
        this.load.isLoading.next(false);
        this.ref.detectChanges();
      }
    });

    this.appBuilderSubscription = this.ws.appBuilderContainer$.subscribe(async (data) => {

      if (data != null && data.reference === this.component_ref && !data.error) {
        console.log("APP BUILDER");
        setTimeout(() => this.mdw.saveBuilderDB(data), 1000);
      }else {
        console.log("APP BUILDER ERROR");
        // disable the App builder
        // reroute to the dashboard
        this.load.isLoading.next(false);
        this.ref.detectChanges();
      }



      // let appHome: any = await this.indexDBService.getItemOnsucss("app", "home");
      // console.log("appHome");
      // console.log(appHome);
      // if (appHome && appHome.app && appHome.app.tabs) {
      //   this.colors = {color_schema: appHome.app.tabs.color_schema, color_schema_ios: appHome.app.tabs.color_schema_ios};
      //   this.changeTheme();
      // }

    });

    // this.schemeChangeSubscription = this.mdw.schemeChangeContainer$.subscribe((data) => {
    //   if(data){
        // console.log("COLORS");
    //     this.changeTheme();
    //   }
    // })

    // this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe((data) => {
    //   if(data && data.component_id != this.component_id){
    //     console.log("COLORS");
    //     console.log(data);
    //     this.colors = data;
    //     this.colors['component_id']= this.component_id;
    //     this.changeTheme();
    //   }
    // })

    if (!localStorage.getItem("light") && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.mode = "dark";
      this.es.broadcast("changeMode", "dark");
      if (this.colors) {
        this.colors['light'] = false;
        // this.mdw._colorsComponentContainer.next(this.colors)
        this.changeTheme();
      }
    } else if (!localStorage.getItem("light")) {
      this.mode = "light";
      this.es.broadcast("changeMode", "light");
        if (this.colors){
          this.colors['light'] = true;
          // this.mdw._colorsComponentContainer.next(this.colors)
          this.changeTheme();
        }
    }

    this.storeSubscription = this.mdw.storeContainer$.subscribe(data => {
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const id = Number(data[i].id);
          this.ws.processMethod(new SetMStore(
            data[i].menu_id,
            null,
            data[i].shop,
            data[i].image,
            data[i].name,
            data[i].description,
            id,
            data[i].is_default,
          ));
        }
      }
    });

    this.pollSubscription = this.mdw.pollContainer$.subscribe(data => {
      console.log('poll', data);
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const id = Number(data[i].id);
          this.ws.processMethod(
            new SetPoll(
            id,
            data[i].menu_id,
            data[i].name,
            data[i].menus,
          ));
        }
      }
    });

    this.getStoreMethodSubscription = this.mdw.getStoreMethodContainer$.subscribe(data => {
       if (data) {
        const id = Number(data);
        this.ws.processMethod(new GetStore(id));
      }else {
        // get the default store
         this.ws.processMethod(new GetStore(0));
      }
    })

    this.getStoreSubscription = this.ws.getStoreContainer$.subscribe(data => {
      if (data && data.data && data.data.id ) {
        let store: any;
        store = data.data;
        store.id = store.id.toString();
        this.mdw.saveStore(store)
      }
    })

    this.getPollMethodSubscription = this.mdw.getPollMethodContainer$.subscribe(data => {
      if (data) {
       const id = Number(data);
       this.ws.processMethod(new GetPoll(id));
     }
   })

   this.getPollSubscription = this.ws.pollContainer$.subscribe(data => {
    if (data) {

      let poll: any;
      poll = data.data;
      poll.id = poll.id.toString();

     this.mdw.savePoll(poll)
    }
  })
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (!localStorage.getItem("light") && this.colors) {
        this.mode = event.matches ? "dark" : "light";
        if (this.mode == "light") {
          this.es.broadcast("changeMode", "light");
          this.colors['light'] = true;
          // this.mdw._colorsComponentContainer.next( this.colors)
        } else {
          this.es.broadcast("changeMode", "dark");
          this.colors['light'] = false;
          // this.mdw._colorsComponentContainer.next( this.colors)
        }
        this.changeTheme();
      }
    });
  }

  selectChat(id: any) {
    this.ws.processMethod(new SelectChat(id, 0, this.component_ref));
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
  /**
   * Toggles the right sidebar
   */
  toggleMode() {
    console.log("COLRO");
    console.log(this.colors);
    if (this.colors) {
      if (this.mode === "light") {
        this.mode = "dark";
        this.es.broadcast("changeMode", "dark");
        this.colors['light'] = false;
        localStorage.setItem("light", "false");
        // this.mdw._colorsComponentContainer.next( this.colors)
      } else if (this.mode === "dark") {
        this.mode = "light";
        this.es.broadcast("changeMode", "light");
        this.colors['light'] = true;
        localStorage.setItem("light", "true");
        // this.mdw._colorsComponentContainer.next( this.colors)
      }
      this.changeTheme();
    }
  }

  changeTheme() {
    // // this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;
    if(localStorage.getItem("light")){
      this.mode = localStorage.getItem("light") == "true" ? "light" : "dark";
    }
    if (this.mode === "light") {
      let color_schema = this.colors.color_schema.schemes.light;
      let color_schema_ios = this.colors.color_schema_ios?.schemes?.light;
      this.scheme = this.isAndroid ? color_schema : color_schema_ios;
      (document.getElementsByClassName("logo-light")[0] as HTMLElement).style.display = "block";
      (document.getElementsByClassName("logo-dark")[0] as HTMLElement).style.display = "none";

      color_schema = this.colors.color_schema.schemes.dark;
      color_schema_ios = this.colors.color_schema_ios?.schemes?.dark;
      let dark_scheme = this.isAndroid ? color_schema : color_schema_ios;
      document.documentElement.style.setProperty("--offcanvas-3d-bottom", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-3d-top", dark_scheme.surfaceContainerLowest);

      document.documentElement.style.setProperty("--offcanvas-bg", dark_scheme.surfaceContainer);
      document.documentElement.style.setProperty("--offcanvas-content-bg", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-parent-bg", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-icon-bg", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-submenu-bg", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-card-container-hover-bg", dark_scheme.surfaceContainerLowest);
      document.documentElement.style.setProperty("--offcanvas-card-container-bg", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-header", dark_scheme.surface);
      document.documentElement.style.setProperty("--offcanvas-header-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-active-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-hover-text", dark_scheme.secondary);
      document.documentElement.style.setProperty("--offcanvas-inactive-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-divider", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-primary", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-sub-menu-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-icon", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-scrollbar-color", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-outline", dark_scheme.outline);
      document.documentElement.style.setProperty("--offcanvas-outline-variant", dark_scheme.outlineVariant);
      document.documentElement.style.setProperty("--offcanvas-drag-icon", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-input-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-text", this.scheme.onPrimary);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-bg", dark_scheme.surfaceContainerLow);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-bg", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-error", dark_scheme.error);

      document.documentElement.style.setProperty("--icon-close", dark_scheme.onSurface);

      document.documentElement.style.setProperty("--disabled-shade", dark_scheme.surface);
      document.documentElement.style.setProperty("--disabled-shade-text", dark_scheme.onSurface);

      document.documentElement.style.setProperty("--offcanvas-subcard", dark_scheme.surfaceContainer);

      document.documentElement.style.setProperty("--sidebar", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--sidebar-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--sidebar-active-text", dark_scheme.primary);
      document.documentElement.style.setProperty("--sidebar-hover-text", dark_scheme.secondary);
      document.documentElement.style.setProperty("--sidebar-sub-menu-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--sidebar-icon", dark_scheme.onSurface);
    } else if (this.mode === "dark") {
      let color_schema = this.colors.color_schema.schemes.dark;
      let color_schema_ios = this.colors.color_schema_ios?.schemes?.dark;
      this.scheme = this.isAndroid ? color_schema : color_schema_ios;
      (document.getElementsByClassName("logo-light")[0] as HTMLElement).style.display = "none";
      (document.getElementsByClassName("logo-dark")[0] as HTMLElement).style.display = "block";
      document.documentElement.style.setProperty("--offcanvas-3d-bottom", this.scheme.surfaceContainerLowest);
      document.documentElement.style.setProperty("--offcanvas-3d-top", this.scheme.surfaceContainerHighest);

      document.documentElement.style.setProperty("--offcanvas-bg", this.scheme.surfaceContainer);
      document.documentElement.style.setProperty("--offcanvas-content-bg", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-parent-bg", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-icon-bg", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-submenu-bg", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-card-container-hover-bg", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-card-container-bg", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-header", this.scheme.surfaceDim);
      document.documentElement.style.setProperty("--offcanvas-header-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-active-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-hover-text", this.scheme.secondary);
      document.documentElement.style.setProperty("--offcanvas-inactive-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-divider", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-primary", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-sub-menu-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-icon", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-scrollbar-color", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-outline", this.scheme.outline);
      document.documentElement.style.setProperty("--offcanvas-outline-variant", this.scheme.outlineVariant);
      document.documentElement.style.setProperty("--offcanvas-drag-icon", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-input-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-text", this.scheme.onPrimary);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-bg", this.scheme.surfaceContainerLow);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-bg", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-error", this.scheme.error);

      document.documentElement.style.setProperty("--icon-close", this.scheme.onSurface);

      document.documentElement.style.setProperty("--disabled-shade", this.scheme.surface);
      document.documentElement.style.setProperty("--disabled-shade-text", this.scheme.onSurface);

      document.documentElement.style.setProperty("--offcanvas-subcard", this.scheme.surfaceContainer);

      document.documentElement.style.setProperty("--sidebar", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--sidebar-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--sidebar-active-text", this.scheme.primary);
      document.documentElement.style.setProperty("--sidebar-hover-text", this.scheme.secondary);
      document.documentElement.style.setProperty("--sidebar-sub-menu-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--sidebar-icon", this.scheme.onSurface);
    }

    document.documentElement.style.setProperty("--card-body", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--card-text", this.scheme.onSurface);

    document.documentElement.style.setProperty("--card-channel-body", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--card-channel-text", this.scheme.onSurface);

    document.documentElement.style.setProperty("--subcard", this.scheme.surfaceContainer);

    document.documentElement.style.setProperty("--topbar", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--topbar-icon", this.scheme.onSurface);

    document.documentElement.style.setProperty("--tab-selected", this.scheme.primary);
    document.documentElement.style.setProperty("--tab-border-outline-variant", this.scheme.outlineVariant);

    document.documentElement.style.setProperty("--switch-bg", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--switch-border", this.scheme.outline);
    document.documentElement.style.setProperty("--switch-checked-bg", this.scheme.primary);
    document.documentElement.style.setProperty("--switch-dot", this.scheme.outline);
    document.documentElement.style.setProperty("--switch-checked-dot", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--switch-text", this.scheme.outline);
    document.documentElement.style.setProperty("--switch-checked-text", this.scheme.onPrimary);

    document.documentElement.style.setProperty("--table-header-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--table-header-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--table-header-bg", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--table-bg", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--table-striped-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--table-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--table-hover-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--table-hover-bg", this.scheme.surfaceDim);

    document.documentElement.style.setProperty("--input-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--input-disabled-bg", this.scheme.surfaceDim);
    document.documentElement.style.setProperty("--input-addon-bg", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--input-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--input-placeholder", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--input-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--input-selected-bg", this.scheme.surfaceContainerHighest);

    document.documentElement.style.setProperty("--module-bar-bg", this.scheme.secondary);
    document.documentElement.style.setProperty("--module-bar-ripple-before", this.scheme.secondaryDim);
    document.documentElement.style.setProperty("--module-bar-ripple-after", this.scheme.secondary);
    document.documentElement.style.setProperty("--module-bar-text", this.scheme.onSecondary);
    document.documentElement.style.setProperty("--module-bar-icon", this.scheme.onSecondary);

    document.documentElement.style.setProperty("--settings-btn-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--settings-btn-text", this.scheme.onSurfaceVariant);

    document.documentElement.style.setProperty("--icon-button-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--icon-button-icon", this.scheme.onSurfaceVariant);

    document.documentElement.style.setProperty("--calendar-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--calendar-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--calendar-hover", this.scheme.surfaceDim);
    document.documentElement.style.setProperty("--calendar-selected", this.scheme.primary);
    document.documentElement.style.setProperty("--calendar-selected-text", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--calendar-disabled", this.scheme.outline);

    document.documentElement.style.setProperty("--dropzone-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--dropzone-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--dropzone-remove-text", this.scheme.error);

    document.documentElement.style.setProperty("--list-group-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--list-group-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--list-group-text", this.scheme.onSurface);

    document.documentElement.style.setProperty("--swal-bg", this.scheme.surfaceContainer);

    document.documentElement.style.setProperty("--loader-bg", this.scheme.surfaceContainerHigh);

    document.documentElement.style.setProperty("--dropdown-bg", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--dropdown-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--dropdown-hover-bg", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--dropdown-hover-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--dropdown-border", this.scheme.outlineVariant);

    document.documentElement.style.setProperty("--nav-link-hover-text", this.scheme.onSurfaceVariant);

    document.documentElement.style.setProperty("--md-sys-color-outline", this.scheme.outline);

    document.documentElement.style.setProperty("--md-outlined-button-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-hover-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-focus-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-pressed-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-icon-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-icon-size", '20px');
    document.documentElement.style.setProperty("--md-outlined-button-hover-icon-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-focus-icon-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-pressed-icon-color", this.scheme.primary);

    document.documentElement.style.setProperty("--md-filled-button-container-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-filled-button-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-hover-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-focus-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-pressed-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-icon-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-hover-icon-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-focus-icon-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-pressed-icon-color", this.scheme.onPrimary);

    document.documentElement.style.setProperty("--md-filled-tonal-button-container-color", this.scheme.secondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-label-text-color", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-hover-label-text-color", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-focus-label-text-color", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-pressed-label-text-color", this.scheme.onSecondaryContainer);

    document.documentElement.style.setProperty("--md-elevated-button-container-color", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--md-elevated-button-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-elevated-button-hover-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-elevated-button-focus-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-elevated-button-pressed-label-text-color", this.scheme.primary);

    document.documentElement.style.setProperty("--primary", this.scheme.primary);
    document.documentElement.style.setProperty("--onPrimary", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--primaryContainer", this.scheme.primaryContainer);
    document.documentElement.style.setProperty("--onPrimaryContainer", this.scheme.onPrimaryContainer);
    document.documentElement.style.setProperty("--secondary", this.scheme.secondary);
    document.documentElement.style.setProperty("--onSecondary", this.scheme.onSecondary);
    document.documentElement.style.setProperty("--secondaryContainer", this.scheme.secondaryContainer);
    document.documentElement.style.setProperty("--onSecondaryContainer", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--tertiary", this.scheme.tertiary);
    document.documentElement.style.setProperty("--onTertiary", this.scheme.onTertiary);
    document.documentElement.style.setProperty("--tertiaryContainer", this.scheme.tertiaryContainer);
    document.documentElement.style.setProperty("--onTertiaryContainer", this.scheme.onTertiaryContainer);

    document.documentElement.style.setProperty("--success", this.scheme.success);
    document.documentElement.style.setProperty("--onSuccess", this.scheme.onSuccess);
    document.documentElement.style.setProperty("--error", this.scheme.error);
    document.documentElement.style.setProperty("--onError", this.scheme.onError);
    document.documentElement.style.setProperty("--errorContainer", this.scheme.errorContainer);
    document.documentElement.style.setProperty("--onErrorContainer", this.scheme.onErrorContainer);
    document.documentElement.style.setProperty("--outline", this.scheme.outline);
    document.documentElement.style.setProperty("--outline-variant", this.scheme.outlineVariant);

    document.documentElement.style.setProperty("--success-button-bg", this.scheme.success);
    document.documentElement.style.setProperty("--success-button-text", this.scheme.onSuccess);
    document.documentElement.style.setProperty("--success-container-button-bg", this.scheme.successContainer);
    document.documentElement.style.setProperty("--success-container-button-text", this.scheme.onSuccessContainer);

    document.documentElement.style.setProperty("--danger-button-bg", this.scheme.error);
    document.documentElement.style.setProperty("--danger-button-text", this.scheme.onError);
    document.documentElement.style.setProperty("--danger-container-button-bg", this.scheme.errorContainer);
    document.documentElement.style.setProperty("--danger-container-button-text", this.scheme.onErrorContainer);

    document.documentElement.style.setProperty("--warning-container-button-bg", this.scheme.warningContainer);
    document.documentElement.style.setProperty("--warning-container-button-text", this.scheme.onWarningContainer);

    document.documentElement.style.setProperty("--surface-bg", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--surface-text", this.scheme.onSurface);

    document.body.style.backgroundColor = this.scheme.surfaceContainer;
    this.ref.detectChanges();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mdw._layoutRefreshContainer.next(true);
    this.mobileMenuButtonClicked.emit();
  }

  routeRecharge() {
    this.router.navigate(["/account/recharge"]);
  }
  routeBillings() {
    this.router.navigate(["/billings"]);
  }

  /**
   * Logout the user
   */
  logout() {
    this.load.isLoading.next(true);
    this.ws.logout();
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  saveToDB(message: Message, recent: boolean, download: boolean) {
    message.message.gmid = Number(message.message.gmid);
    switch (message.message.status) {
      case message_status_map.DELETED:
        this.indexDBService.updateItem("messages", message).onsuccess = (event) => {
          this.ws._incomingMessageContainer.next(message);
        };
        // console.log("DELETING USER GMID");
        // console.log(message);
        this.indexDBService.deleteItemComposite("user", [message.message.chat.id, message.message.reply_to_message_id, message.message.from.id]);
      break;
      case message_status_map.UPDATED:
        let msg = this._fetchMessage(message.message.message_id);
        let newMessage;

        msg.onsuccess = (event) => {
          if (msg.result) {
            // console.log("msg");
            // console.log(msg);
            newMessage = { ...message };
            newMessage.message.date = msg.result.message.date;
            newMessage.message.gmid = msg.result.message.gmid;
          } else {
            newMessage = message;
            newMessage.message.status = null;
          }
          this.indexDBService.updateItem("messages", newMessage).onsuccess = (event) => {
            this.ws._incomingMessageContainer.next(newMessage);
          };
        };
      break;
      default:
        this.indexDBService.insertItem("messages", message).onsuccess = (event) => {
          const newMsg = { ...message, recent, download };
          this.ws._incomingMessageContainer.next(newMsg);
        };
      break;
    }
  }

  private _fetchMessage(message_id) {
    const requestMessage = this.indexDBService.getItem("messages", message_id);

    return requestMessage;
  }

  getPoll(id){
    this.mdw._getPollMethodContainer.next(id);
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.messageListSubscription) {
      this.messageListSubscription.unsubscribe();
    }
    if (this.menuPageResponseSubscription) {
      this.menuPageResponseSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.getBalanceSubscription) {
      this.getBalanceSubscription.unsubscribe();
    }
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
    if (this.superAdminSubscription) {
      this.superAdminSubscription.unsubscribe();
    }
    if (this.chatListSubscription) {
      this.chatListSubscription.unsubscribe();
    }
    if (this.selectedChatSubscription) {
      this.selectedChatSubscription.unsubscribe();
    }
    if (this.userListSubscription) {
      this.userListSubscription.unsubscribe();
    }
    if (this.schemeChangeSubscription) {
      this.schemeChangeSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.getAppConfigOnceSubscription) {
      this.getAppConfigOnceSubscription.unsubscribe();
    }
    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.getStoreSubscription) {
      this.getStoreSubscription.unsubscribe();
    }
    if (this.getStoreMethodSubscription) {
      this.getStoreMethodSubscription.unsubscribe();
    }
    if (this.pollSubscription) {
      this.pollSubscription.unsubscribe();
    }
    if (this.getPollMethodSubscription) {
      this.getPollMethodSubscription.unsubscribe();
    }
    if (this.getPollSubscription) {
      this.getPollSubscription.unsubscribe();
    }
  }
}
