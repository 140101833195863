import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-tabsplash-button",
  templateUrl: "./button-tabsplash-button.component.html",
  styleUrls: ["./button-tabsplash-button.component.scss"],
})

export class ButtonTabSplashButtonComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() tabData: Tab;

  imageConfig: any = {};
  button: MenuButton;
  image: string;
//
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  tab: Tab;
  textColor: string;
  bgColor: string;
  button_title: string;


  ngOnInit() {
    this.field_id = 'tabsplashbutton'
    this.sortedButtonList = [];
    this.buttonList = {};

    this.lableform = this.formBuilder.group({
      lableControl: new UntypedFormControl(''),

    });

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {

        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] !== this.component_id
        ) {

          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          let tab = data["splash_tab"]
          this.buttonFunction(tab)


          }
        }
    );
  }

 intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.tabData)
  }

  buttonFunction(tabData) {
    if (tabData && tabData.param && tabData.param.splash) {
      this.tab = tabData;
      // this.button_title = this.tab.param.splash.button_title;
      this.button_title = this.builderService.getTabSplashItem(this.tab.param.splash, 'button_title', this.tab.param.splash.style);

      // this.textColor = this.tab.param.splash.button_text_color;
      this.textColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'button_text_color', this.tab.param.splash.style);

      // this.bgColor = this.tab.param.splash.button_bgcolor;
      this.bgColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'button_bgcolor', this.tab.param.splash.style);

      if (this.button_title) {
        this.lableform = this.formBuilder.group({
          lableControl: new UntypedFormControl(this.button_title),
        });
      }
    }
  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button_title= this.f.lableControl.value;
      this.tab.param.splash.button_title= this.button_title;
      this.updateButtonData(this.tab)
    }
  }

  get f() {
    if (this.lableform &&  this.lableform.controls) {
      return this.lableform.controls;
    }

  }



  buttonBgColor(item, value, manual) {
    let color = null;
    color = (manual == true)?this.isValidHexaCode(value.target.value)? value.target.value :null : value

    if (color){
      if (item==='text') {
            this.textColor = color
            this.tab.param.splash.button_text_color = this.textColor;
            }
          if (item=== 'bg'){
            this.bgColor =color;
            this.tab.param.splash.button_bgcolor = this.bgColor;
           }

     this.updateButtonData(this.tab)

    }

  }


  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return true;
    }
    else {
        return false;
    }
}




  // buttonBgColor(item,value) {
  //   if (item==='text') {
  //     this.textColor = value
  //     this.tab.param.splash.button_text_color = this.textColor;
  //     }
  //   if (item=== 'bg'){
  //     this.bgColor =value;
  //     this.tab.param.splash.button_bgcolor = this.bgColor;
  //    }
  //   this.updateButtonData(this.tab)

  // }

  updateButtonData(tab?) {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;
    this.data["splash_tab"] = this.tab;


    this.mdw._currentMenuContainer.next(this.data);

if (tab){
  this.mdw.changeTab(
    { button: this.button, tab: this.tab },
    this.mode,
    false
  );

}else {
  this.mdw.changeTab(
    { button: this.button, tab: { module: "menu" } },
    this.mode,
    true
  );
}


  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
