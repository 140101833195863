<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="card m-0 bg-transparent" style="border: 1px solid;" >
      <div class="card-body">
        <div class="bg-transparent p-0 mb-3">
          <h6 class="offcanvas-header-text text-start">Styles</h6>
        </div>
        <ng-container *ngFor="let theme of bubbleThemes">
          <div class="row mb-3 d-flex justify-content-center">
            <div class="col-7 p-2 rounded-4 " [class.border]="theme.key === bubble_theme" [class.border-primary]="theme.key === bubble_theme" (click)="themeChange(theme.key)">
              <img [src]="theme.image"/>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
