<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row mb-3" class="offcanvas-divider">
      <div class="col-6 d-flex justify-content-end ">
        <label
        for="text-color"
        class="offcanvas-header-text w-100"
        style="text-align: left"
      >
      {{   'GENERALS.CENTER' | translate}}
      </label>
      </div>
    </div>


    <div class="row">

      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.LATITUDE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="lat"
            #lat
            class="form-control input-lg offcanvas-primary border-0"
            [value]="button.button_page_data.map_options.center.lat"
            (change)="changeLocationCenter(lat.value,lng.value )"

          />
        </div>
      </div>


      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.LONGITUDE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="lng"
            #lng
            class="form-control input-lg offcanvas-primary border-0"
            [value]="button.button_page_data.map_options.center.lng"
            (change)="changeLocationCenter(lat.value,lng.value )"
          />
        </div>

    </div>
  </div>
  </div>
</div>
