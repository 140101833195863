<div class="card m-0 bg-transparent">
  <div class="card-body">

    <!-- <ng-container *ngFor="let item of tab?.footer?.action"> -->




    <div class="row mb-3">
      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.RADIUS' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="number"
            id="mapRadius"
            #mapRadius
            class="form-control input-lg offcanvas-primary border-0"
            [value]="raduis"
            (change)="radiusChange(mapRadius?.value)"
          />

        </div>
      </div>

    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="offcanvas-header-text w-100 m-0" for="formReply">
            {{   'GENERALS.MODE' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can select mode "
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <!-- <div class="col-12 btn-group m-0" dropdown >
          <button
            type="button"
            class="btn col-11 offcanvas-primary fs-6 d-flex align-items-center justify-content-start"

          >
          {{searchMode}}
          </button>
          <button
            type="button"
            class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            dropdownToggle
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let mode of listMode | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="changeMode(mode?.key)">{{mode?.value}}</a>
          </div>
        </div> -->
        <div class="col-12 dropdown" dropdown>
          <button class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*dropdownMenuButton" dropdownToggle>
            {{searchMode}} <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let mode of listMode | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="changeMode(mode?.key)">{{mode?.value}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 offcanvas-header-text d-flex align-items-center">
          {{   'GENERALS.FIRSTMAPBUTTON' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="firstBtn? true: false"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="addRemoveFirstBtn($event)"
        ></ui-switch>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">

        <div class="col-12">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
          {{   'GENERALS.TITLE' | translate}}
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="firstTitle"
              #firstTitle
              class="form-control input-lg offcanvas-primary border-0"
              [value]="firstBtnTitle"
              [disabled]="firstBtn? false: true"
              (change)="btnTitle(firstTitle?.value, firstBtn)"
            />

          </div>
        </div>


    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BUTTONCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="firstBtnBg"
            [disabled]="firstBtn ? false: true"
            (change)="btnBg($event,firstBtn)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0"
            [ngClass]="!firstBtn ? 'disabled' : 'offcanvas-primary'"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="firstBtnBg"
              [(colorPicker)]="firstBtnBg"

              [cpPosition]="'bottom-left'"
              (colorPickerChange)="btnBg($event,firstBtn)"
            >
            </span>
          </span>
        </div>
      </div>
      </div>
      <div class="row d-flex justify-content-between align-items-center mb-3">
        <div class="col-12">
          <div class="form-check d-flex align-items-center m-0 p-0">
            <label class="offcanvas-header-text w-100 m-0" for="formReply">
              {{   'GENERALS.ACTION' | translate}}
              <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can select mode "
              ></i>
            </label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end"    >
          <!-- <div class="col-12 btn-group m-0" dropdown [isDisabled]="firstBtn ? false: true">
            <button
              type="button"
              class="btn col-11 offcanvas-primary fs-6 d-flex align-items-center justify-content-start"
              [disabled]="firstBtn ? false: true"
            >
            {{makerTypeList[firstBtnlink]}}
            </button>
            <button
              type="button"
              class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
              dropdownToggle
            >
              <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="col-12 dropdown-menu" *dropdownMenu>
              <a *ngFor="let maker of makerTypeList | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="btnLink(maker?.key,firstBtn)">{{maker?.value}}</a>
            </div>
          </div> -->

          <div class="col-12 dropdown" dropdown [isDisabled]="firstBtn ? false: true">
            <button    [disabled]="firstBtn ? false: true" class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*firstBtn" dropdownToggle>
              {{makerTypeList[firstBtnlink]}}  <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="col-12 dropdown-menu" *dropdownMenu>
              <a *ngFor="let maker of makerTypeList | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="btnLink(maker?.key,firstBtn)">{{maker?.value}}</a>
            </div>
          </div>
        </div>
      </div>

    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 offcanvas-header-text d-flex align-items-center">
          {{   'GENERALS.SECMAPBUTTON' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="secBtn ? true: false"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="addRemoveSecBtn($event)"
        ></ui-switch>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center mb-3">

      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.TITLE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="secTitle"
            #secTitle
            class="form-control input-lg offcanvas-primary border-0"
            [value]="secBtnTitle"
            [disabled]="secBtn? false: true"
            (change)="btnTitle(secTitle?.value, secBtn)"
          />

        </div>
      </div>


  </div>
  <div class="row mb-3">
    <div class="col-6">
      <label
        for="text-color"
        class="offcanvas-header-text w-100"
        style="text-align: left"
      >
      {{   'GENERALS.BUTTONCOLOR' | translate}}
      </label>
      <div
        class="input-group offcanvas-border-primary border rounded-2"
        title="Using format option"
      >
        <input
          type="text"
          id="text-color"
          class="form-control input-lg offcanvas-primary border-0"
          [(ngModel)]="secBtnBg"
          [disabled]="secBtn ? false: true"
          (change)="btnBg($event,secBtn)"
        />
        <span
          class="input-group-text p-1 bg-transparent border-0"
          [ngClass]="!secBtn ? 'disabled' : 'offcanvas-primary'"
        >
          <span
            class="input-group-text colorpicker-input-addon h-100"
            [cpOutputFormat]="'hex'"
            [style.background]="secBtnBg"
            [(colorPicker)]="secBtnBg"

            [cpPosition]="'bottom-left'"
            (colorPickerChange)="btnBg($event,secBtn)"
          >
          </span>
        </span>
      </div>
    </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="offcanvas-header-text w-100 m-0" for="formReply">
            {{   'GENERALS.ACTION' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can select mode "
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end"    >
        <!-- <div class="col-12 btn-group m-0" dropdown [isDisabled]="secBtn ? false: true">
          <button
            type="button"
            class="btn col-11 offcanvas-primary fs-6 d-flex align-items-center justify-content-start"
            [disabled]="secBtn ? false: true"
          >
          {{makerTypeList[secBtnlink]}}
          </button>
          <button
            type="button"
            class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            dropdownToggle
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let maker of makerTypeList | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="btnLink(maker?.key,secBtn)">{{maker?.value}}</a>
          </div>
        </div> -->

        <div class="col-12 dropdown" dropdown [isDisabled]="secBtn ? false: true">
          <button    [disabled]="secBtn ? false: true" class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*firstBtn" dropdownToggle>
            {{makerTypeList[secBtnlink]}}  <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let maker of makerTypeList | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="btnLink(maker?.key,secBtn)">{{maker?.value}}</a>
          </div>
        </div>
      </div>
    </div>


    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 offcanvas-header-text d-flex align-items-center">
          {{   'GENERALS.THIRDMAPBUTTON' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="thirdBtn ? true: false"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="addRemoveThirdBtn($event)"
        ></ui-switch>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center mb-3">

      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.TITLE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="thirdTitle"
            #thirdTitle
            class="form-control input-lg offcanvas-primary border-0"
            [value]="thirdBtnTitle"
            [disabled]="thirdBtn? false: true"
            (change)="btnTitle(thirdTitle?.value, thirdBtn)"
          />

        </div>
      </div>


  </div>
  <div class="row mb-3">
    <div class="col-6">
      <label
        for="text-color"
        class="offcanvas-header-text w-100"
        style="text-align: left"
      >
      {{   'GENERALS.BUTTONCOLOR' | translate}}
      </label>
      <div
        class="input-group offcanvas-border-primary border rounded-2"
        title="Using format option"
      >
        <input
          type="text"
          id="text-color"
          class="form-control input-lg offcanvas-primary border-0"
          [(ngModel)]="thirdBtnBg"
          [disabled]="thirdBtn ? false: true"
          (change)="btnBg($event,thirdBtn)"
        />
        <span
          class="input-group-text p-1 bg-transparent border-0"
          [ngClass]="!thirdBtn ? 'disabled' : 'offcanvas-primary'"
        >
          <span
            class="input-group-text colorpicker-input-addon h-100"
            [cpOutputFormat]="'hex'"
            [style.background]="thirdBtnBg"
            [(colorPicker)]="thirdBtnBg"

            [cpPosition]="'bottom-left'"
            (colorPickerChange)="btnBg($event,thirdBtn)"
          >
          </span>
        </span>
      </div>
    </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="offcanvas-header-text w-100 m-0" for="formReply">
            {{   'GENERALS.ACTION' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can select mode "
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end"    >
        <!-- <div class="col-12 btn-group m-0" dropdown [isDisabled]="thirdBtn ? false: true">
          <button
            type="button"
            class="btn col-11 offcanvas-primary fs-6  d-flex align-items-center justify-content-start"
            [disabled]="thirdBtn ? false: true"
          >
          {{makerTypeList[thirdBtnlink]}}
          </button>
          <button
            type="button"
            class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            dropdownToggle
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let maker of makerTypeList | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="btnLink(maker?.key,thirdBtn)">{{maker?.value}}</a>
          </div>
        </div> -->

        <div class="col-12 dropdown" dropdown [isDisabled]="thirdBtn ? false: true">
          <button    [disabled]="thirdBtn ? false: true" class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*firstBtn" dropdownToggle>
            {{makerTypeList[thirdBtnlink]}}  <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let maker of makerTypeList | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="btnLink(maker?.key,thirdBtn)">{{maker?.value}}</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
