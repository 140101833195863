<div class="card m-0 bg-transparent">
  <div class="card-body">

    <div class="card m-0 bg-transparent" style="border: 1px solid;" >
      <div class="card-body">
      <div class="card-header bg-transparent p-0 mb-3">
        <h6 class="offcanvas-header-text text-start">Styles</h6>
      </div>
<ng-container *ngFor="let galleryStyle of galleryTypes">
  <div class="row mb-3 d-flex justify-content-center ">
<div  class="col-8 p-2 rounded rounded-4 " [class.border]="galleryStyle.key === button_style" [class.border-primary]="galleryStyle.key === button_style" (click)="styleChange(galleryStyle.key)">

  <div class="card m-0 bg-transparent">

    <img class="card-img img-fluid" [src]=" galleryStyle.image " />

  </div>
  </div>
</div>
</ng-container>

  </div>
</div>
</div>
</div>
