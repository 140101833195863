<!-- <div class="row">
  <div class="col-12"> -->
<!-- col -->

<div class="img-container p-3" style="height: 50vh">
  <!-- <image-cropper
                imageBase64="data:image/jpeg;base64,{{image}}"
                [maintainAspectRatio]="true"
                [aspectRatio]="normalized_ratio"
                [resizeToWidth]="maxWidth"
                [resizeToHeight]="maxHeight"
                [imageQuality]="imageQuality"
                [hideResizeSquares]="hideResizeSquares"
                format="jpeg"
                (imageCropped)="imageCropped($event)"
                [transform]="transform"
              >
              </image-cropper> -->
  <image-cropper
    imageBase64="data:image/jpeg;base64,{{ image }}"
    output="base64"
    [disabled]="disabled"
    [maintainAspectRatio]="true"
    [aspectRatio]="normalized_ratio"
    [resizeToWidth]="maxWidth"
    [resizeToHeight]="maxHeight"
    [format]="format"
    [onlyScaleDown]="onlyScaleDown"
    [hideResizeSquares]="hideResizeSquares"
    (imageCropped)="imageCropped($event)"
    [transform]="transform"
  >
  </image-cropper>
  <!-- </div>
  </div> -->

  <!-- end col -->
</div>
<div class="modal-footer">
  <button (click)="saveImage()" class="btn btn-success w-md">Save</button>
</div>
<!-- end row -->
