<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form [formGroup]="labelform">
      <div
        *ngIf="config?.style"
        class="row d-flex justify-content-between align-items-center mb-3"
      >
        <div class="col-12 m-0">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            {{ config?.name }}
          </label>
          <select
            id="selectoption"
            class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
            formControlName="style"
            (change)="changeStyle('style', 0)"
          >
            <option *ngFor="let item of stylesChoices" [value]="item?.value">
              {{ isAndroid ? item?.label : item?.label_ios }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center mb-3"
        *ngIf="config?.mirror"
      >
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <label for="mirror" class="offcanvas-header-text m-0">
            {{ "GENERALS.MIRROR" | translate }}
          </label>
          <input
            type="checkbox"
            id="mirror"
            class="form-check-input offcanvas-border-primary offcanvas-primary"
            style="width: 18px; height: 18px"
            formControlName="mirror"
            (change)="changeDividerStyle('mirror', 1)"
          />
        </div>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center mb-3"
        *ngIf="config?.divider"
      >
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <label for="divider" class="offcanvas-header-text m-0">
            {{ "GENERALS.DIVIDER" | translate }}
          </label>
          <input
            type="checkbox"
            id="divider"
            class="form-check-input offcanvas-border-primary offcanvas-primary"
            style="width: 18px; height: 18px"
            formControlName="divider"
            (change)="changeDividerStyle('divider', 2)"
          />
        </div>
      </div>
      <div
        *ngIf="config?.card"
        class="row d-flex justify-content-between align-items-center mb-3"
      >
        <div class="col-12 m-0">
          <label
            for="card-type"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Card Type
          </label>
          <div class="row m-0">
            <div class="col-4 p-2 rounded-3" [ngClass]="labelform.get('cardType').value == 0 ? 'border border-white' : ''">
              <div class="card m-0 rounded-3 curser-pointer" style="height: 60px; background: #FAF1F0;" (click)="changeCardStyle(0)">
                <div class="card-body" style="color: #857373;">
                  <i
                    class="bx bx-dots-vertical-rounded position-absolute"
                    style="top: 5px; right: 5px"
                  ></i>
                  <p
                    class="position-absolute m-0"
                    style="bottom: 5px; left: 5px"
                  >
                    Elevated
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4 p-2 rounded-3" [ngClass]="labelform.get('cardType').value == 1 ? 'border border-white' : ''">
              <div class="card m-0 rounded-3 curser-pointer shadow-none" style="height: 60px; background: #F4DDDC;" (click)="changeCardStyle(1)">
                <div class="card-body" style="color: #857373;">
                  <i
                    class="bx bx-dots-vertical-rounded position-absolute"
                    style="top: 5px; right: 5px"
                  ></i>
                  <p
                    class="position-absolute m-0"
                    style="bottom: 5px; left: 5px"
                  >
                    Filled
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4 p-2 rounded-3" [ngClass]="labelform.get('cardType').value == 2 ? 'border border-white' : ''">
              <div class="card m-0 border-1 curser-pointer rounded-3 shadow-none" style="height: 60px; border-color: #857373; background: #FFFBFA;" (click)="changeCardStyle(2)">
                <div class="card-body" style="color: #857373;">
                  <i
                    class="bx bx-dots-vertical-rounded position-absolute"
                    style="top: 5px; right: 5px"
                  ></i>
                  <p
                    class="position-absolute m-0"
                    style="bottom: 5px; left: 5px"
                  >
                    Outlined
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
