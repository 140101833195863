<div class="card m-0 bg-transparent">
  <div class="card-body">

    <div class="row m-0 mb-3">
      <div class="col-6">
        <button
          class="btn btn-primary"
          (click)="openscreenselector(selectorScreen)"
        >
          Select
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary" (click)="nextMenu(button)">
          {{ button && button.nexttab_id ? "Edit" : "Create New" }}
        </button>
      </div>
    </div>
    <div class="row m-0 no-gutters align-items-center">
      <div class="col-6 d-flex justify-content-center" >
        <div class="card-body border border-1 rounded-3 border-primary" >
          <app-mobile-view-tab
           *ngIf="isTab"
          [inputTab]="nextTab"
          [fontSmaller]="true"
          >
        </app-mobile-view-tab>
          <app-mobile-view-slider
          *ngIf="!isTab"
          [fontSmaller]="true"
          [inputMenuId]="menuId"
          [colors]="colors">
        </app-mobile-view-slider>
        </div>
      </div>
      <div class="col-6">
      </div>
    </div>

  </div>
</div>

<ng-template #selectorScreen let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Screen</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-screen-selector
      (selectedItemNotify)="onSelectScreen($event)"
    ></app-screen-selector>
  </div>
</ng-template>
