<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="mb-3">
    <div class="row">

      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
        {{   'GENERALS.EMBDEDURL' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="url"
            #url
            class="form-control input-lg offcanvas-primary border-0"
            [value]="button.button_page_data.video_url"
            (change)="changeVideoUrl(url.value)"
          />

        </div>
      </div>
    </div>
  </div>
  </div>
</div>
