import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MobileView, TabNames, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-common-tab-component",
  templateUrl: "./common-tab-component.component.html",
  styleUrls: ["./common-tab-component.component.scss"],
})
export class CommonTabComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    public indexDBService: IndexDBService,
  ) { }

  @Input() inputTab: Tab;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  @Input() noTabs;

  tab: Tab;
  // default values

  imageLg: string;
  imageSm: string;
  image: string =null;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  updateBuilderComponentSubscribtion: any;
  theme: any = {};

  aLong: string = "./assets/images/emptyImageL.webp";
  aShort: string = "./assets/images/emptyImageS.webp";
  iLong: string = "";
  iShort: string = "";

  afull: string = "";
  ifull: string = "";
  aShortWide: string = "./assets/images/emptyImageS.webp";

  view: string;


  ngOnInit() {
    if (this.inputTab) {
      this.tab = { ...this.inputTab };
      if (this.tab) {

        this.viewTab(this.tab)
      }

    }

    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data) {
          if (this.inputTab) {
            this.tab = { ...this.inputTab };
          } else {
            this.tab = { ...data.tab };
          }
        this.viewTab(this.tab)

        }
      });
  }
  viewTab(tab) {
    switch (tab.type) {
      case TabNames.OPENCHAT:
      case TabNames.CHANNEL:
      case TabNames.OPENBOT:
      case TabNames.CALL_LOG:
      case TabNames.CHAT:
      case TabNames.VIDEOAUDIO:
      case TabNames.CONTACT:
      case TabNames.CHANNELLIST:
      case TabNames.ONLINEGROUP:
      case TabNames.MAP_SEARCH:
      case TabNames.BOOKINGLIST:
      case TabNames.CALENDAR:
      case TabNames.VALIDATE_TICKET:
      case TabNames.OPENCHAT:
      case TabNames.REDEEM_TICKET:
      case TabNames.QR_PAGE:
      case TabNames.GROUP:
      case TabNames.INVITATION:
      case TabNames.ONLINECHANNEL:
      case TabNames.OPENGROUP:
      case TabNames.EMPTY:

        this.theme = this.mdw.mobileTheme(tab)

        this.image = this.theme.image;
        this.roundedBottom = this.theme.roundedBottom;
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
