import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-info-component",
  templateUrl: "./tab-info-component.component.html",
  styleUrls: ["./tab-info-component.component.scss"],
})
export class TabInfoComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) { }

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;

  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;

  off_canvas_key: string = "tab";
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  modalRef?: BsModalRef;
  isAndroid: boolean;
  data: any;
  tabList = { };
  sortedTabList = [ ];
  current_tab_id
  component_id: string;
  field_id: string;

  ngOnInit() {
    this.field_id= "tab_info";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe((data) => {

      console.log("data from Tab subsc inside the Tab info", data);


      if (data && ( data["cat"] == "home" || data["cat"] == "side") && data.off_canvas_key === "tab" && data.currentTab
        && this.tab_id && data.currentTab.id== this.tab_id && data['component_id']!= this.component_id ) {
        this.data = data;
        this.sortedTabList = this.data["sortedTabList"];
        this.tabList = this.data["tabList"];
        this.tab = data["tabList"][this.tab_id];
        this.tabFunction();

        console.log("This tab from Current Tab", this.tab);
      }
    });

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {

        console.log("data from Off Subc inside the Tab info", data);

        if (data && data.off_canvas_key === "tab" &&   data.tab.id == this.tab_id  ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }


  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList =this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }


  tabFunction(){
    if (this.tab){
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;
         if (this.tab.title || this.tab.title_ios) {
          this.title = this.isAndroid ? this.tab.title : this.tab.title_ios;
        }

        if (this.tab.icon) {
          this.icon = this.builderService.getIcon(this.tab.icon);
        }

        if (this.tab.icon_ios) {
          this.icon_ios = this.builderService.getIcon(this.tab.icon_ios);
        }
    }
  }



  iconChange(event) {
    this.icon = this.builderService.getIcon(event);
    this.tab.icon = event;
    this.modalRef.hide();
    this.updateTabData()
  }
  iosIconChange(event) {
    this.icon_ios = this.builderService.getIcon(event);
    this.tab.icon_ios = event;
    this.modalRef.hide();
    this.updateTabData()
  }
  titleChange(event) {
    this.tab.title = event;
    this.tab.title_ios = event;
    this.updateTabData()
  }

  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }



  updateTabData(){
  //*must be there in all Tabs
  this.tabList[this.tab_id] = this.tab;

  let index = this.sortedTabList.findIndex(
    (e) => e.id == this.tab_id
  );
  this.sortedTabList[index] = { ...this.tab };
  this.data["sortedTabList"] = this.sortedTabList;
  this.data["TabList"] = this.tabList;
  this.data["off_canvas_key"] = this.off_canvas_key;
  this.data["currenTab"] = this.tab;

  this.mdw._currentTabContainer.next(this.data);
  this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
