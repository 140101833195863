import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-splashlabel-html",
  templateUrl: "./button-splashlabel-html.component.html",
  styleUrls: ["./button-splashlabel-html.component.scss"],
})
export class ButtonSplashLabelHtmlComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService:BuilderService
  ) {}

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  button: MenuButton;
  mode: string;
  public Editor = customBuild
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;

  defaultConfig: EditorConfig = {

    fontColor:{
      colors:['#000000', '#808080','#c0c0c0','#ffffff', '#800000', '#ff0000', '#800080', '#ff00ff', '#008000', '#00ff00', '#808000', '#ffff00', '#000080', '#0000ff', '#008080', '#00ffff'],
      colorPicker:{
        format:'hex'
      }
    },
    toolbar: {
      items: ["undo", "redo", "Heading", "bold", "italic",'fontColor'],
    },
    language: "en",
  };

  ngOnInit() {
    this.lableform = this.formBuilder.group({
      lableControl: new UntypedFormControl(''),
    });

    this.field_id = "label";

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;
    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
      this.button_label = this.builderService.getButtonSplashItem(this.button, 'button_label',this.data.splashTab.param.splash.style);
      if (this.button_label){
        this.lableform = this.formBuilder.group({
          lableControl: new UntypedFormControl(this.button_label),
        });
      }

  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button.button_label = this.f.lableControl.value;
      this.updateButtonData();
    }
  }

  get f() {
    if (this.lableform && this.lableform.controls) {
      return this.lableform.controls;
    }
  }

  colorChange(event) {
    this.button.button_label_color = this.labelColor;
    this.updateButtonData();
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "splash" } },
      this.mode,
      true
    );
  }

  public onReady( editor: customBuild ): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(
      editor.ui.view.toolbar.element!,
      element
    );
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
