<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          Common Description
        </label>
        <div
          class="input-group offcanvas-border-primary border-0 m-0 p-0 rounded-0"
          title="Using format option"
        >
          <form class="needs-validation w-100" [formGroup]="lableform">
              <ckeditor
              id="lablecolor"
              formControlName="lableControl"
              class="form-control input-lg offcanvas-ck-primary border-0 w-100 p-0 rounded-0"
              [config]="defaultConfig"
              [editor]="Editor"
              data="title"
              (blur)="buttonLabel()"
              (ready)="onReady($event)"
            ></ckeditor>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
